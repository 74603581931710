// ** React Imports
import { lazy } from 'react'

// Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const Onboarding = Loadable(lazy(() => import('../../views/onboarding/Onboarding')))

// ==============================|| ONBOARDING ROUTING ||============================== //

const OnboardingRoutes = {
    path: LINK_MAP.ONBOARDING,
    element: <Onboarding />,
}

export default OnboardingRoutes
