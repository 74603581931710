// ** React Imports
import { useRoutes } from 'react-router-dom'

// ** Project imports
import PublicRoutes from './public'
import AuthRoutes from './auth'
import NonFoundPageRoute from './404'

export default function AppRoutes() {
    return useRoutes([...PublicRoutes, ...AuthRoutes, NonFoundPageRoute])
}
