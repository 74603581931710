import { createSlice } from '@reduxjs/toolkit'

const organizationUsersViewSlice = createSlice({
    name: 'organizationUsersView',
    initialState: {
        inviteUserDialog: {
            isActive: false,
            isSuccessAcitve: false,
            invitedUserEmail: null,
            assignedOrgRole: null,
        },
        deleteUserDialog: {
            isActive: false,
            isSuccessAcitve: false,
            deletedUserEmail: null,
        },
        changeUserOrgRoleDialog: {
            isActive: false,
            isSuccessAcitve: false,
            userEmail: null,
            newRole: null,
            newRoleUIAlias: null,
        },
        assignProjectsToUserDialog: {
            isActive: false,
            userEmail: null,
            userId: null,
            userProjects: [],
        },
    },
    reducers: {
        toggleInviteUserDialog(state, action) {
            state.inviteUserDialog.isActive = action.payload
        },
        toggleSuccessInviteUserDialog(state, action) {
            const { isSuccessAcitve, invitedUserEmail, assignedOrgRole } = action.payload

            state.inviteUserDialog.isSuccessAcitve = isSuccessAcitve
            state.inviteUserDialog.invitedUserEmail = invitedUserEmail
                ? invitedUserEmail
                : state.inviteUserDialog.invitedUserEmail
            state.inviteUserDialog.assignedOrgRole = assignedOrgRole
                ? assignedOrgRole
                : state.inviteUserDialog.assignedOrgRole
        },
        toggleDeleteUserDialog(state, action) {
            const { isActive, deletedUserEmail } = action.payload

            state.deleteUserDialog.isActive = isActive
            state.deleteUserDialog.deletedUserEmail = deletedUserEmail
                ? deletedUserEmail
                : state.deleteUserDialog.deletedUserEmail
        },
        toggleSuccessDeleteUserDialog(state, action) {
            const { isSuccessAcitve, deletedUserEmail } = action.payload

            state.deleteUserDialog.isSuccessAcitve = isSuccessAcitve
            state.deleteUserDialog.deletedUserEmail = deletedUserEmail
                ? deletedUserEmail
                : state.deleteUserDialog.deletedUserEmail
        },
        toggleChangeUserOrgRoleDialog(state, action) {
            const { isActive, userEmail, newRole, newRoleUIAlias } = action.payload

            state.changeUserOrgRoleDialog.isActive = isActive
            state.changeUserOrgRoleDialog.userEmail = userEmail
                ? userEmail
                : state.changeUserOrgRoleDialog.userEmail
            state.changeUserOrgRoleDialog.newRole = newRole
                ? newRole
                : state.changeUserOrgRoleDialog.newRole
            state.changeUserOrgRoleDialog.newRoleUIAlias = newRoleUIAlias
                ? newRoleUIAlias
                : state.changeUserOrgRoleDialog.newRoleUIAlias
        },
        toggleSuccessChangeUserOrgRoleDialog(state, action) {
            const { isSuccessAcitve, userEmail, newRoleUIAlias } = action.payload

            state.changeUserOrgRoleDialog.isSuccessAcitve = isSuccessAcitve
            state.changeUserOrgRoleDialog.userEmail = userEmail
                ? userEmail
                : state.changeUserOrgRoleDialog.userEmail
            state.changeUserOrgRoleDialog.newRole = null
            state.changeUserOrgRoleDialog.newRoleUIAlias = newRoleUIAlias
                ? newRoleUIAlias
                : state.changeUserOrgRoleDialog.newRoleUIAlias
        },
        toggleAssignProjectsToUserDialog(state, action) {
            const { isActive, userId, userEmail, userProjects } = action.payload

            state.assignProjectsToUserDialog.isActive = isActive
            state.assignProjectsToUserDialog.userId = userId
            state.assignProjectsToUserDialog.userEmail = userEmail
            state.assignProjectsToUserDialog.userProjects = userProjects
        },
        updateUserProjects(state, action) {
            state.assignProjectsToUserDialog.userProjects = action.payload
        },
    },
})

export default organizationUsersViewSlice.reducer

export const {
    toggleInviteUserDialog,
    toggleSuccessInviteUserDialog,
    toggleDeleteUserDialog,
    toggleSuccessDeleteUserDialog,
    toggleChangeUserOrgRoleDialog,
    toggleSuccessChangeUserOrgRoleDialog,
    toggleAssignProjectsToUserDialog,
    updateUserProjects,
} = organizationUsersViewSlice.actions
