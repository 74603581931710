import { AttributionModelKey } from '../types/reports.types'

export const REPORT_TYPES = {
    AD_CHANNELS_TABLE: 'ad_channels_table',
    TRAFFIC_ATTRIBUTION_TABLE: 'traffic_attribution_table',
    EVENTS_TABLE: 'events_table',
    DEALS_TABLE: 'deals_table',
    ORDERS_TABLE: 'orders_table',
} as const

export const ATTRIBUTION_MODELS: { [key: string]: { key: AttributionModelKey; label: string } } = {
    FIRST_CLICK: {
        key: 'first_click',
        label: 'First click',
    },
    LAST_CLICK: {
        key: 'last_click',
        label: 'Last click',
    },
    LAST_NON_DIRECT_CLICK: { key: 'last_non_direct_click', label: 'Last non direct click' },
    LAST_MARKED_CLICK: { key: 'last_marked_click', label: 'Last marked click' },
    LINEAR: { key: 'linear', label: 'Linear' },
    // U_SHAPED: { key: 'u_shaped', label: 'U-Shaped' },
    // CUSTOMER_JOURNEY: { key: 'customer_journey', label: 'Customer journey' },
}

export const ATTRIBUTION_MODELS_KEYS = [
    'first_click',
    'last_click',
    'last_non_direct_click',
    'last_marked_click',
    'linear',
    'u_shaped',
    'customer_journey',
] as const

export const REPORT_ITEM_TYPES = {
    PARAM: 'param',
    METRIC: 'metric',
    COLUMN_GROUP: 'column_group',
} as const
