// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const Login = Loadable(lazy(() => import('../../views/auth/Login')))
const Registration = Loadable(lazy(() => import('../../views/auth/Registration')))
const RequestPasswordReset = Loadable(lazy(() => import('../../views/auth/RequestPasswordReset')))
const ResetPassword = Loadable(lazy(() => import('../../views/auth/ResetPassword')))
const EmailConfirmation = Loadable(lazy(() => import('../../views/auth/EmailConfirmation')))
const AcceptInvitationToOrganization = Loadable(
    lazy(() => import('../../views/invitations/AcceptInvitationToOrganization'))
)
const AcceptInvitationToProject = Loadable(
    lazy(() => import('../../views/invitations/AcceptInvitationToProject'))
)
const PublicOfferAgreement = Loadable(lazy(() => import('../../views/legal/PublicOfferAgreement')))
const PrivacyPolicy = Loadable(lazy(() => import('../../views/legal/PrivacyPolicy')))

const PublicRoutes = [
    {
        path: LINK_MAP.PUBLIC.LOGIN,
        element: <Login />,
    },
    {
        path: LINK_MAP.PUBLIC.REGISTER,
        element: <Registration />,
    },
    {
        path: LINK_MAP.PUBLIC.REQUEST_PASSWORD_RESET,
        element: <RequestPasswordReset />,
    },
    {
        path: LINK_MAP.PUBLIC.RESET_PASSWORD,
        element: <ResetPassword />,
    },
    {
        path: LINK_MAP.PUBLIC.ACTIVATE_EMAIL,
        element: <EmailConfirmation />,
    },
    {
        path: LINK_MAP.PUBLIC.ACCEPT_INVITATION_TO_ORGANIZATION,
        element: <AcceptInvitationToOrganization />,
    },
    {
        path: LINK_MAP.PUBLIC.ACCEPT_INVITATION_TO_PROJECT,
        element: <AcceptInvitationToProject />,
    },
    {
        path: LINK_MAP.PUBLIC.PUBLIC_OFFER_AGREEMENT,
        element: <PublicOfferAgreement />,
    },
    {
        path: LINK_MAP.PUBLIC.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
    },
]

export default PublicRoutes
