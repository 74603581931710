// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'
import { metricApiSlice } from '../../../../api/metric'
import { METRIC_TYPES } from '../../../../constants/metric.constants'

export interface IMetricMeasureCostructorValidation {
    label: string | null
    format_type: string | null
    expression: string | null
}

const initialState = {
    label: null,
    format_type: null,
    expression: null,
} as IMetricMeasureCostructorValidation

const measureMetricConstructorValidationSlice = createSlice({
    name: 'measureMetricConstructorValidation',
    initialState,
    reducers: {
        setInitialValidationState: () => initialState,
        setValidationState: (state, action: PayloadAction<IMetricMeasureCostructorValidation>) => {
            return (state = action.payload)
        },
    },
    extraReducers: (builder) => {
        // Set constructor state on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            return (state = initialState)
        })

        // Set constructor state on logout
        builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
            return (state = initialState)
        })

        // Set constructor state on retrieve metric config
        builder.addMatcher(
            metricApiSlice.endpoints.getProjectMetric.matchFulfilled,
            (state, action) => {
                const { type } = action.payload
                if (type === METRIC_TYPES.MEASURE) return (state = initialState)
            }
        )
    },
})

export default measureMetricConstructorValidationSlice.reducer

export const { setInitialValidationState, setValidationState } =
    measureMetricConstructorValidationSlice.actions
