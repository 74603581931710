// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project Imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface ICreateTikTokAdsCostExportJob {
  connection: { id: string; name: string } | undefined
}

const initialState: ICreateTikTokAdsCostExportJob = {
  connection: undefined,
}

const createTikTokAdsCostExportJobSlice = createSlice({
  name: 'createTikTokAdsCostExportJob',
  initialState,
  reducers: {
    setInitialState: () => initialState,

    setConnection: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const connectionId = action.payload.id
      const connectionName = action.payload.name
      state.connection = { id: connectionId, name: connectionName }
    },
  },
  extraReducers: (builder) => {
    // Set state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default createTikTokAdsCostExportJobSlice.reducer

export const { setInitialState, setConnection } = createTikTokAdsCostExportJobSlice.actions
