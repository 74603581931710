// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Project Imports
import { authorize, unauthorize } from '../auth'
import { authApiSlice } from '../../api/auth'
import { userApiSlice } from '../../api/user'

const initialState = {
    isAuth: false,
    id: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    isEmailConfirmed: false,
}

export const userSlice = createSlice({
    initialState,
    name: 'user',
    reducers: {
        setUserState: (state, action) => {
            const { isAuth, id, firstName, lastName, phone, email, isEmailConfirmed } =
                action.payload

            state.isAuth = isAuth !== undefined ? isAuth : state.isAuth
            state.id = id !== undefined ? id : state.id
            state.firstName = firstName !== undefined ? firstName : state.firstName
            state.lastName = lastName !== undefined ? lastName : state.lastName
            state.phone = phone !== undefined ? phone : state.phone
            state.email = email !== undefined ? email : state.email
            state.isEmailConfirmed =
                isEmailConfirmed !== undefined ? isEmailConfirmed : state.isEmailConfirmed
        },
        setUserInitialState: () => initialState,
    },
    extraReducers: (builder) => {
        // Set user state on authorize
        builder.addCase(authorize, (state, action) => {
            state.isAuth = true
        })

        // Set user state on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            return (state = initialState)
        })

        // Set user state on login
        builder.addMatcher(authApiSlice.endpoints.loginUser.matchFulfilled, (state, action) => {
            state.isAuth = true
        })

        // Set user state on logout
        builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
            return (state = initialState)
        })

        // Set user state on getWorkspaceData
        builder.addMatcher(
            authApiSlice.endpoints.getWorkspaceData.matchFulfilled,
            (state, action) => {
                const { id, email, isEmailConfirmed } = action.payload.user

                state.id = id
                state.email = email
                state.isEmailConfirmed = isEmailConfirmed
            }
        )

        // Set user state on register
        builder.addMatcher(userApiSlice.endpoints.registerUser.matchFulfilled, (state, action) => {
            state.isAuth = true
        })

        // Set user state on reset password
        builder
            .addMatcher(userApiSlice.endpoints.resetPassword.matchFulfilled, (state, action) => {
                state.isAuth = true
            })
            .addMatcher(userApiSlice.endpoints.resetPassword.matchRejected, (state, action) => {
                return (state = initialState)
            })

        // Set user state on get persnoal data
        builder.addMatcher(
            userApiSlice.endpoints.getUserPersonalData.matchFulfilled,
            (state, action) => {
                const { firstName, lastName, phone, email } = action.payload

                state.firstName = firstName !== undefined ? firstName : state.firstName
                state.lastName = lastName !== undefined ? lastName : state.lastName
                state.phone = phone !== undefined ? phone : state.phone
                state.email = email !== undefined ? email : state.email
            }
        )

        // Set user state on update persnoal data
        builder.addMatcher(
            userApiSlice.endpoints.updateUserPersonalData.matchFulfilled,
            (state, action) => {
                const { firstName, lastName, phone } = action.payload

                state.firstName = firstName !== undefined ? firstName : state.firstName
                state.lastName = lastName !== undefined ? lastName : state.lastName
                state.phone = phone !== undefined ? phone : state.phone
            }
        )

        // Set user state on email confirmation
        builder.addMatcher(userApiSlice.endpoints.confirmEmail.matchFulfilled, (state, action) => {
            state.isEmailConfirmed = true
        })
    },
})

export default userSlice.reducer

export const { setUserInitialState, setUserState } = userSlice.actions
