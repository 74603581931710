// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Project Imports
import { unauthorize } from '../auth'
import { authApiSlice } from '../../api/auth'
import { projectApiSlice } from '../../api/project'
import { organizationApiSlice } from '../../api/organization'

const initialState = {
    availableProjects: [],
    activeProject: {
        id: null,
        streamId: null,
        name: null,
        role: null,
        timezone: null,
        currency: null,
    },
}

export const projectsSlice = createSlice({
    initialState,
    name: 'projects',
    reducers: {
        setAvailableProjects: (state, action) => {
            state.availableProjects = action.payload
        },
        addAvailableProject: (state, action) => {
            state.availableProjects = state.availableProjects.push(action.payload)
        },
        deleteAvailableProject: (state, action) => {
            const { projectId } = action.payload

            state.availableProjects = state.availableProjects.filter(
                (project) => project.id !== projectId
            )
        },
        clearAllAvailableProjects: () => initialState,
        setActiveProjectState: (state, action) => {
            const { id, streamId, name, role, timezone, currency } = action.payload

            state.activeProject.id = id
            state.activeProject.streamId = streamId
            state.activeProject.name = name
            state.activeProject.role = role
            state.activeProject.timezone = timezone
            state.activeProject.currency = currency
        },
        clearActiveProjectState: (state, action) => {
            state.activeProject = {
                id: null,
                streamId: null,
                name: null,
                role: null,
                timezone: null,
                currency: null,
            }
        },
    },
    extraReducers: (builder) => {
        // Set projects state on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            return (state = initialState)
        })

        // Set project state on getWorkspaceData
        builder.addMatcher(
            authApiSlice.endpoints.getWorkspaceData.matchFulfilled,
            (state, action) => {
                const { organizations } = action.payload.user

                // Set default available project array
                if (!state.activeProject.id) {
                    const userOwnedOrganization = organizations.filter(
                        (el) => el.role === 'organization.owner'
                    )

                    if (userOwnedOrganization.length === 1) {
                        state.availableProjects =
                            userOwnedOrganization[0].projects !== undefined
                                ? userOwnedOrganization[0].projects
                                : state.availableProjects
                    } else if (organizations.length > 0) {
                        state.availableProjects =
                            organizations[0].projects !== undefined
                                ? organizations[0].projects
                                : state.availableProjects
                    }
                }
            }
        )

        // Set projects state on logout
        builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
            return (state = initialState)
        })

        // Set projects state after creating new organization
        builder.addMatcher(
            organizationApiSlice.endpoints.createOrganization.matchFulfilled,
            (state, action) => {
                state.availableProjects = []

                state.activeProject = {
                    id: null,
                    streamId: null,
                    name: null,
                    role: null,
                    timezone: null,
                    currency: null,
                }
            }
        )

        // Set active project state after creating new project
        builder
            .addMatcher(projectApiSlice.endpoints.createProject.matchFulfilled, (state, action) => {
                const { id, streamId, name, role, timezone, currency } = action.payload

                state.activeProject.id = id
                state.activeProject.streamId = streamId
                state.activeProject.name = name
                state.activeProject.role = role
                state.activeProject.timezone = timezone
                state.activeProject.currency = currency

                state.availableProjects = [
                    ...state.availableProjects,
                    { id, streamId, name, role, timezone, currency },
                ]
            })
            .addMatcher(authApiSlice.endpoints.createProject.matchRejected, (state, action) => {
                state.activeProject = {
                    id: null,
                    streamId: null,
                    name: null,
                    role: null,
                    timezone: null,
                    currency: null,
                }
            })

        // Set project state after updating project info
        builder.addMatcher(
            projectApiSlice.endpoints.updateProject.matchFulfilled,
            (state, action) => {
                const { id, streamId, name, role, timezone, currency } = action.payload

                state.activeProject.id = id
                state.activeProject.streamId = streamId
                state.activeProject.name = name
                state.activeProject.role = role
                state.activeProject.timezone = timezone
                state.activeProject.currency = currency
            }
        )

        // Set project state after delete project
        builder.addMatcher(
            projectApiSlice.endpoints.deleteProject.matchFulfilled,
            (state, action) => {
                state.availableProjects = state.availableProjects.filter(
                    (el) => el.id !== state.activeProject.id
                )

                state.activeProject.id = null
                state.activeProject.streamId = null
                state.activeProject.name = null
                state.activeProject.role = null
                state.activeProject.timezone = null
                state.activeProject.currency = null
            }
        )
    },
})

export default projectsSlice.reducer

export const {
    setAvailableProjects,
    addAvailableProject,
    deleteAvailableProject,
    clearAllAvailableProjects,
    setActiveProjectState,
    clearActiveProjectState,
} = projectsSlice.actions
