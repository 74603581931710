// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface IParamDefinitionsDialogs {
  createParamDialog: {
    isActive: boolean
  }
  deleteParamDialog: {
    isActive: boolean
    paramId: string | null
    paramName: string | null
  }
  updateParamDialog: {
    isActive: boolean
    paramId: string | null
    paramConfig: {
      id: string
      label: string
      description: string
      level: string
      var_type: string
      selection_key: string
      group_id: string | null
    } | null
  }
  createParamGroupDialog: {
    isActive: boolean
  }
  updateParamGroupDialog: {
    isActive: boolean
    groupId: string | null
    paramGroupName: string | null
  }
  deleteParamGroupDialog: {
    isActive: boolean
    groupId: string | null
    paramGroupName: string | null
  }
}

const initialState = {
  createParamDialog: {
    isActive: false,
  },
  updateParamDialog: {
    isActive: false,
    paramId: null,
    paramConfig: null,
  },
  deleteParamDialog: {
    isActive: false,
    paramId: null,
    paramName: null,
  },
  createParamGroupDialog: {
    isActive: false,
  },
  updateParamGroupDialog: {
    isActive: false,
    groupId: null,
    paramGroupName: null,
  },
  deleteParamGroupDialog: {
    isActive: false,
    groupId: null,
    paramGroupName: null,
  },
} as IParamDefinitionsDialogs

const paramDefinitionsWidgetsSlice = createSlice({
  name: 'paramDefinitionsWidgets',
  initialState,
  reducers: {
    toggleCreateParamDialog(state, action: PayloadAction<boolean>) {
      state.createParamDialog.isActive = action.payload
    },
    toggleUpdateParamDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        paramConfig: {
          id: string
          label: string
          description: string
          level: string
          var_type: string
          selection_key: string
          group_id: string | null
        } | null
      }>
    ) {
      const { isActive, paramConfig } = action.payload
      state.updateParamDialog.isActive = isActive
      state.updateParamDialog.paramConfig = paramConfig
    },
    toggleDeleteParamDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        paramId: string | null
        paramName: string | null
      }>
    ) {
      const { isActive, paramId, paramName } = action.payload
      state.deleteParamDialog.isActive = isActive
      state.deleteParamDialog.paramId = paramId
      state.deleteParamDialog.paramName = paramName
    },
    toggleCreateParamGroupDialog(state, action: PayloadAction<boolean>) {
      state.createParamGroupDialog.isActive = action.payload
    },
    toggleUpdateParamGroupDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        groupId: string | null
        paramGroupName: string | null
      }>
    ) {
      const { isActive, groupId, paramGroupName } = action.payload
      state.updateParamGroupDialog.isActive = isActive
      state.updateParamGroupDialog.groupId = groupId
      state.updateParamGroupDialog.paramGroupName = paramGroupName
    },
    toggleDeleteParamGroupDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        groupId: string | null
        paramGroupName: string | null
      }>
    ) {
      const { isActive, groupId, paramGroupName } = action.payload
      state.deleteParamGroupDialog.isActive = isActive
      state.deleteParamGroupDialog.groupId = groupId
      state.deleteParamGroupDialog.paramGroupName = paramGroupName
    },
  },
  extraReducers: (builder) => {
    // Set state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default paramDefinitionsWidgetsSlice.reducer

export const {
  toggleCreateParamDialog,
  toggleUpdateParamDialog,
  toggleDeleteParamDialog,
  toggleCreateParamGroupDialog,
  toggleUpdateParamGroupDialog,
  toggleDeleteParamGroupDialog,
} = paramDefinitionsWidgetsSlice.actions
