// ** React imports
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// ** Project imports
import { LINK_MAP } from '../../../config/link-map'

export default function Guard(props) {
    const location = useLocation()
    const navigate = useNavigate()

    const { isUserDataFetched } = props

    const isAuth = useSelector((state) => state.user.isAuth)
    const isEmailConfirmed = useSelector((state) => state.user.isEmailConfirmed)
    const isOrganizationExists = useSelector(
        (state) => state.organizations.availableOrganizations.length > 0
    )
        ? true
        : false

    const authGuard = useSelector((state) => state.guard.authGuard)
    const guestGuard = useSelector((state) => state.guard.guestGuard)
    const returnRedirectUrl = useSelector((state) => state.guard.returnRedirectUrl)

    const authController = () => {
        // If auth user has unconfirmed email or doesn't have an organization - redirect him to Onboarding page
        if (
            (authGuard || guestGuard) &&
            isAuth &&
            isUserDataFetched &&
            (!isEmailConfirmed || !isOrganizationExists)
        ) {
            navigate(LINK_MAP.ONBOARDING, { replace: false })
        }

        // If auth user is on public page - redirect him to Homepage
        else if (
            guestGuard &&
            isAuth &&
            isUserDataFetched &&
            isEmailConfirmed &&
            isOrganizationExists
        ) {
            // If return url exists - redirect user to it and prevent redirecting to onboarding page
            if (returnRedirectUrl && returnRedirectUrl !== LINK_MAP.ONBOARDING) {
                navigate(returnRedirectUrl, { replace: false })
            } else {
                navigate(LINK_MAP.HOME, { replace: true })
            }
        }

        // If unauthorized user tries to see protected page - redirect him to Login page
        else if (authGuard && !isAuth) {
            // Prevent duplication returnUrl in URL
            if (!location.search.split('returnUrl=')[1]) {
                navigate({
                    pathname: LINK_MAP.PUBLIC.LOGIN,
                    search: `returnUrl=${location.pathname}${location.search}`,
                    replace: true,
                })
            }
        }

        // If onboarded user tries to open Onboarding page - redirect him to Homepage
        else if (
            isAuth &&
            isUserDataFetched &&
            isEmailConfirmed &&
            isOrganizationExists &&
            location.pathname === LINK_MAP.ONBOARDING
        ) {
            navigate(LINK_MAP.HOME, { replace: true })
        }
    }

    useEffect(() => {
        authController()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        location.pathname,
        isAuth,
        authGuard,
        guestGuard,
        isEmailConfirmed,
        isOrganizationExists,
        isUserDataFetched,
    ])

    return <>{props.children}</>
}
