// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const Reports = Loadable(lazy(() => import('../../views/reports/Reports')))
const CreateReport = Loadable(lazy(() => import('../../views/reports/CreateReport')))
const ReportTypesRouter = Loadable(lazy(() => import('../../components/reports/ReportTypesRouter')))
const ParamsDefinitions = Loadable(lazy(() => import('../../views/reports/ParamsDefinitions')))
const MetricsDefinitions = Loadable(lazy(() => import('../../views/reports/MetricsDefinitions')))
const CalculatedColumnMetricConstructor = Loadable(
    lazy(() => import('../../views/reports/CalculatedColumnMetricConstructor'))
)
const MeasureMetricConstructor = Loadable(
    lazy(() => import('../../views/reports/MeasureMetricConstructor'))
)

// ==============================|| REPORTS ROUTING ||============================== //

const ReportsRoutes = [
    {
        path: LINK_MAP.REPORTS.ROOT,
        element: <Reports />,
    },
    {
        path: `${LINK_MAP.REPORTS.ROOT}/${LINK_MAP.REPORTS.CHILD.CREATE}`,
        element: <CreateReport />,
    },
    {
        path: `${LINK_MAP.REPORTS.ROOT}/:reportId`,
        element: <ReportTypesRouter />,
    },
    {
        path: `${LINK_MAP.PARAM_DEFINITIONS.ROOT}/${LINK_MAP.PARAM_DEFINITIONS.CHILD.CUSTOM}`,
        element: <ParamsDefinitions />,
    },
    {
        path: `${LINK_MAP.PARAM_DEFINITIONS.ROOT}/${LINK_MAP.PARAM_DEFINITIONS.CHILD.SYSTEM}`,
        element: <ParamsDefinitions />,
    },
    {
        path: `${LINK_MAP.METRIC_DEFINITIONS.ROOT}/${LINK_MAP.METRIC_DEFINITIONS.CHILD.CUSTOM}`,
        element: <MetricsDefinitions />,
    },
    {
        path: `${LINK_MAP.METRIC_DEFINITIONS.ROOT}/${LINK_MAP.METRIC_DEFINITIONS.CHILD.SYSTEM}`,
        element: <MetricsDefinitions />,
    },
    {
        path: `${LINK_MAP.METRIC_DEFINITIONS.ROOT}/${LINK_MAP.METRIC_DEFINITIONS.CHILD.CREATE_CALCULATED_COLUMN}`,
        element: <CalculatedColumnMetricConstructor />,
    },
    {
        path: `${LINK_MAP.METRIC_DEFINITIONS.ROOT}/${LINK_MAP.METRIC_DEFINITIONS.CHILD.UPDATE_CALCULATED_COLUMN}/:metricId`,
        element: <CalculatedColumnMetricConstructor />,
    },
    {
        path: `${LINK_MAP.METRIC_DEFINITIONS.ROOT}/${LINK_MAP.METRIC_DEFINITIONS.CHILD.CREATE_MEASURE}`,
        element: <MeasureMetricConstructor />,
    },
    {
        path: `${LINK_MAP.METRIC_DEFINITIONS.ROOT}/${LINK_MAP.METRIC_DEFINITIONS.CHILD.UPDATE_MEASURE}/:metricId`,
        element: <MeasureMetricConstructor />,
    },
]

export default ReportsRoutes
