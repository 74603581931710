// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface ITikTokAdsCostExportJobDialogs {
  deleteJobDialog: {
    isActive: boolean
    jobId: string | null
    jobName: string | null
  }
}

const initialState = {
  deleteJobDialog: {
    isActive: false,
    jobId: null,
    jobName: null,
  },
} as ITikTokAdsCostExportJobDialogs

const tiktokAdsCostExportJobDialogSlice = createSlice({
  name: 'tiktokAdsCostExportJobDialog',
  initialState,
  reducers: {
    toggleDeleteTikTokAdsCostExportJobDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        jobId: string | null
        jobName: string | null
      }>
    ) {
      const { isActive, jobId, jobName } = action.payload

      state.deleteJobDialog.isActive = isActive
      state.deleteJobDialog.jobId = jobId
      state.deleteJobDialog.jobName = jobName
    },
  },
  extraReducers: (builder) => {
    // Set state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default tiktokAdsCostExportJobDialogSlice.reducer

export const { toggleDeleteTikTokAdsCostExportJobDialog } =
  tiktokAdsCostExportJobDialogSlice.actions
