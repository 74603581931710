// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'

// Routes
import OnboardingRoutes from './onboarding'
import HomeRoutes from './home'
import ReportsRoute from './reports'
import TrackingRoutes from './tracking'
import IntegrationsRoutes from './integrations'
import ProjectRoutes from './project'
import OrganizatonRoutes from './organization'
import ProfileRoutes from './profile'
import OthersRoutes from './others'

// ** Lazy imports
const MainLayout = Loadable(lazy(() => import('../../shared/ui/widgets/MainLayout')))

// ==============================|| APP ROUTING ||============================== //

const AuthRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            OnboardingRoutes,
            ...HomeRoutes,
            ...ReportsRoute,
            ...TrackingRoutes,
            ...IntegrationsRoutes,
            ...ProjectRoutes,
            ...OrganizatonRoutes,
            ...ProfileRoutes,
            ...OthersRoutes,
        ],
    },
]

export default AuthRoutes
