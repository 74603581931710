// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const UpcomingReleases = Loadable(lazy(() => import('../../views/others/UpcomingReleases')))

// ==============================|| HOME ROUTING ||============================== //

const OthersRoutes = [
    {
        path: LINK_MAP.UPCOMING_RELEASES,
        element: <UpcomingReleases />,
    },
]

export default OthersRoutes
