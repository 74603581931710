// ** React imports
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

// ** Project imports
import { authorize, unauthorize } from '../store/auth'
import { API_GATEWAY_HOSTS } from '../config/api-gateway-hosts'

const BASE_URL =
    process.env.NODE_ENV === 'development'
        ? API_GATEWAY_HOSTS.DEVELOPMENT
        : API_GATEWAY_HOSTS.PRODUCTION

// create a new mutex
const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
    baseUrl: `${BASE_URL}api/v1/`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = getState().guard.accessToken

        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`)
        }

        return headers
    },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()

            try {
                const refreshResult = await baseQuery('auth/refresh', api, extraOptions)

                if (refreshResult?.data?.accessToken) {
                    // Refresh workspace data
                    api.dispatch(
                        authorize({
                            guard: {
                                accessToken: refreshResult.data.accessToken,
                            },
                        })
                    )

                    // Retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(unauthorize())
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }

    return result
}

export const apiSlice = createApi({
    reducerPath: 'apiSlice',
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Workspace',
        'OrganizationUsers',
        'ProjectUsers',
        'ClientSideTracking',
        'ServerSideTracking',
        'ProjectReports',
        'ProjectDataMapping',
        'ProjectInstalledIntegrations',
        'ProjectFBAdsCostExportConnections',
        'ProjectFBAdsCostExportJobs',
        'ProjectGoogleAdsCostExportConnections',
        'ProjectGoogleAdsCostExportJobs',
        'ProjectParams',
        'ProjectParamGroups',
        'ProjectMetrics',
        'ProjectMetricGroups',
        'OrganizationSubscription',
    ],
    endpoints: () => ({}),
})
