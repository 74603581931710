export const LINK_MAP = {
    PUBLIC: {
        LOGIN: '/login',
        REGISTER: '/register',
        REQUEST_PASSWORD_RESET: '/request-password-reset',
        RESET_PASSWORD: '/reset-password',
        ACTIVATE_EMAIL: '/email-confirmation',
        ACCEPT_INVITATION_TO_ORGANIZATION: '/accept-organization-invitation',
        ACCEPT_INVITATION_TO_PROJECT: '/accept-project-invitation',
        PUBLIC_OFFER_AGREEMENT: '/public-offer-agreement',
        PRIVACY_POLICY: '/privacy',
    },
    HOME: '/home',
    ONBOARDING: '/onboarding',
    REPORTS: {
        ROOT: '/reports',
        CHILD: {
            CREATE: 'create',
        },
    },
    METRIC_DEFINITIONS: {
        ROOT: '/metric-definitions',
        CHILD: {
            CUSTOM: 'custom',
            SYSTEM: 'system',
            CREATE_CALCULATED_COLUMN: 'create-calculated-column',
            UPDATE_CALCULATED_COLUMN: 'update-calculated-column',
            CREATE_MEASURE: 'create-measure',
            UPDATE_MEASURE: 'update-measure',
        },
    },
    PARAM_DEFINITIONS: {
        ROOT: '/param-definitions',
        CHILD: {
            CUSTOM: 'custom',
            SYSTEM: 'system',
        },
    },
    TRACKING: {
        ROOT: '/tracking',
        CHILD: {
            CLIENT_SIDE: 'client-side',
            SERVER_SIDE: 'server-side',
        },
    },
    INTEGRATIONS: {
        ROOT: '/integrations',
        CHILD: {
            CREATE: 'create',
            FACEBOOK_ADS_AD_COST_EXPORT_CREATE: 'create/facebook-ads-ad-cost-export',
            FACEBOOK_ADS_AD_COST_EXPORT_CONNECTIONS: 'facebook-ads-ad-cost-export/connections',
            FACEBOOK_ADS_AD_COST_EXPORT_JOBS: 'facebook-ads-ad-cost-export/jobs',
            GOOGLE_ADS_AD_COST_EXPORT_CREATE: 'create/google-ads-ad-cost-export',
            GOOGLE_ADS_AD_COST_EXPORT_CONNECTIONS: 'google-ads-ad-cost-export/connections',
            GOOGLE_ADS_AD_COST_EXPORT_CONNECTIONS_PROCESS_AUTH_CODE:
                'google-ads-ad-cost-export/connections/process-authorization-code',
            GOOGLE_ADS_AD_COST_EXPORT_JOBS: 'google-ads-ad-cost-export/jobs',
            TIKTOK_ADS_AD_COST_EXPORT_CREATE: 'create/tiktok-ads-ad-cost-export',
            TIKTOK_ADS_AD_COST_EXPORT_CONNECTIONS: 'tiktok-ads-ad-cost-export/connections',
            TIKTOK_ADS_AD_COST_EXPORT_CONNECTIONS_PROCESS_AUTH_CODE:
                'tiktok-ads-ad-cost-export/connections/process-authorization-code',
            TIKTOK_ADS_AD_COST_EXPORT_JOBS: 'tiktok-ads-ad-cost-export/jobs',
        },
    },
    PROJECT: {
        ROOT: '/project',
        CHILD: {
            CREATE: 'create',
            PROJECT_INFO: 'info',
            USERS: 'users',
        },
    },
    ORGANIZATION: {
        ROOT: '/organization',
        CHILD: {
            CREATE: 'create',
            ORGANIZATION_INFO: 'info',
            USERS: 'users',
            PLAN_AND_USAGE: 'plan-and-usage',
            PLAN_BUILDER: 'plan-builder',
            PAYMENT_STATUS: 'payments',
        },
    },
    PROFILE: {
        ROOT: '/profile',
        CHILD: {
            PERSONAL_DATA: 'personal-info',
            SEQURITY: 'sequrity',
        },
    },
    UPCOMING_RELEASES: '/upcoming-releases',
}
