import { createTheme } from '@mui/material'

const themeLigth = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#00BCD4',
            light: '#E8FCFF',
            dark: '#00ACC1',
            contrastText: '#fff',
            shades: {
                p8: 'rgba(0, 188, 212, 0.08)',
                p12: 'rgba(0, 188, 212, 0.12)',
                p30: 'rgba(0, 188, 212, 0.3)',
                p50: 'rgba(0, 188, 212, 0.5)',
            },
        },
        secondary: {
            main: '#2196F3',
            light: '#E3F2FD',
            dark: '#1E88E5',
            contrastText: '#fff',
        },
        warning: {
            main: '#ED6C02',
            light: '#FF9800',
            dark: '#E65100',
            contrastText: '#fff',
            shades: {
                p8: 'rgba(237, 108, 2, 0.08)',
                p12: 'rgba(237, 108, 2, 0.12)',
                p30: 'rgba(237, 108, 2, 0.3)',
                p50: 'rgba(237, 108, 2, 0.5)',
            },
        },
        background: {
            paper: '#fff',
            default: '#E8FCFF',
        },
        authBackground: '#E8FCFF',
        orange: {
            100: '#FFE0B2',
            700: '#F57C00',
        },
    },
    mixins: {
        toolbar: {
            minHeight: '56px',
            '@media (min-width: 0px)': {
                '@media (orientation: landscape)': {
                    minHeight: '64px',
                },
            },
            '@media (min-width: 600px)': {
                minHeight: '64px',
            },
        },
    },
    typography: {
        fontSize: 14,
    },
    sidebars: {
        width: '220px',
    },
    // components: {
    //   MuiCssBaseline: {
    //     styleOverrides: {
    //       body: {
    //         scrollbarColor: '#6b6b6b #2b2b2b',
    //         '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    //           backgroundColor: '#2b2b2b',
    //         },
    //         '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    //           borderRadius: 8,
    //           backgroundColor: '#6b6b6b',
    //           minHeight: 24,
    //           border: '3px solid #2b2b2b',
    //         },
    //         '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    //           backgroundColor: '#959595',
    //         },
    //         '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
    //           backgroundColor: '#959595',
    //         },
    //         '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    //           backgroundColor: '#959595',
    //         },
    //         '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
    //           backgroundColor: '#2b2b2b',
    //         },
    //       },
    //     },
    //   },
    // },
})

export default themeLigth
