// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Project Imports
import { projectApiSlice } from '../../api/project'
import {
    IMetricCalculatedColumn,
    IMetricMeasure,
} from '../../interfaces/domain/reports/metric.interface'
import { IMetricsGroup } from '../../interfaces/domain/reports/metrics-group.interface'
import { IParam } from '../../interfaces/domain/reports/param.interface'
import { IParamsGroup } from '../../interfaces/domain/reports/params-group.interface'
import {
    ITableColumnBand,
    ITableColumnBandStyle,
} from '../../interfaces/domain/reports/table-column-band.interface'
import { unauthorize } from '../auth'
import { utilsApiSlice } from '../../api/utils'

interface IProjectDataMapping {
    isLoadedDataMapping: boolean
    params: IParam[]
    metrics: (IMetricCalculatedColumn | IMetricMeasure)[]
    paramsGroups: IParamsGroup[]
    metricsGroups: IMetricsGroup[]
    tableColumnsBands: ITableColumnBand[]
    tableColumnsBandsStyles: ITableColumnBandStyle[]
    isLoadedTableColumnsBandsStyles: boolean
}

const initialState = {
    isLoadedDataMapping: false,
    params: [],
    metrics: [],
    paramsGroups: [],
    metricsGroups: [],
    tableColumnsBands: [],
    tableColumnsBandsStyles: [],
    isLoadedTableColumnsBandsStyles: false,
} as IProjectDataMapping

export const dataMappingSlice = createSlice({
    initialState,
    name: 'dataMapping',
    reducers: {
        setInitialDataMappingState: () => initialState,
    },
    extraReducers: (builder) => {
        // Set project data mapping state  on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            return (state = initialState)
        })

        // Set project data mapping state on getProjectDataMapping
        builder.addMatcher(
            projectApiSlice.endpoints.getProjectDataMapping.matchFulfilled,
            (state, action) => {
                const dataMapping = action.payload

                state.params = dataMapping?.params ?? []
                state.metrics = dataMapping?.metrics ?? []
                state.paramsGroups = dataMapping?.paramsGroups ?? []
                state.metricsGroups = dataMapping?.metricsGroups ?? []
                state.tableColumnsBands = dataMapping?.tableColumnsBands ?? []
                state.isLoadedDataMapping = true
            }
        )

        // Set project data mapping state on getProjectDataMapping
        builder.addMatcher(
            utilsApiSlice.endpoints.getTableColumsBandsStyles.matchFulfilled,
            (state, action) => {
                state.tableColumnsBandsStyles = action.payload
                state.isLoadedTableColumnsBandsStyles = true
            }
        )
    },
})

export default dataMappingSlice.reducer

export const { setInitialDataMappingState } = dataMappingSlice.actions
