// // ** Project imports
import { apiSlice } from '..'

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: ({ organizationId, projectName, timezone, currency }) => ({
        url: `organization/${organizationId}/projects`,
        method: 'POST',
        body: { projectName, timezone, currency },
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
      }),
      invalidatesTags: ['Workspace'],
    }),
    updateProject: builder.mutation({
      query: ({ organizationId, projectId, projectName, timezone, currency }) => ({
        url: `organization/${organizationId}/projects/${projectId}`,
        method: 'PATCH',
        body: { projectName, timezone, currency },
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
      }),
      invalidatesTags: ['Workspace'],
    }),
    deleteProject: builder.mutation({
      query: ({ organizationId, projectId }) => ({
        url: `organization/${organizationId}/projects/${projectId}`,
        method: 'DELETE',
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ['Workspace'],
    }),
    getProjectUsers: builder.query({
      query: ({ organizationId, projectId }) => ({
        url: `organization/${organizationId}/projects/${projectId}/users`,
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
      }),
      providesTags: ['ProjectUsers'],
    }),
    addProjectUser: builder.mutation({
      query: ({ organizationId, projectId, email, role }) => ({
        url: `organization/${organizationId}/projects/${projectId}/users`,
        method: 'POST',
        body: { email, role },
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
      }),
      invalidatesTags: ['ProjectUsers'],
    }),
    updateProjectUser: builder.mutation({
      query: ({ organizationId, projectId, email, role }) => ({
        url: `organization/${organizationId}/projects/${projectId}/users`,
        method: 'PATCH',
        body: { email, role },
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
      }),
      invalidatesTags: ['ProjectUsers'],
    }),
    deleteProjectUser: builder.mutation({
      query: ({ organizationId, projectId, email }) => ({
        url: `organization/${organizationId}/projects/${projectId}/users`,
        method: 'DELETE',
        body: { email },
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
        responseHandler: (response) => response.text(),
      }),
      invalidatesTags: ['ProjectUsers'],
    }),
    getProjectDataMapping: builder.query({
      query: ({ organizationId, projectId }) => ({
        url: `organization/${organizationId}/projects/${projectId}/data-mapping`,
        validateStatus: (response) => response.status >= 200 && response.status <= 299,
      }),
      providesTags: ['ProjectDataMapping'],
    }),
  }),
})

export const {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetProjectUsersQuery,
  useAddProjectUserMutation,
  useUpdateProjectUserMutation,
  useDeleteProjectUserMutation,
  useDeleteProjectMutation,
  useGetProjectDataMappingQuery,
  useLazyGetProjectDataMappingQuery,
} = projectApiSlice
