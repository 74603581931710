// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project Imports
import { unauthorize } from '../../auth'
import { authApiSlice } from '../../../api/auth'
import { metricApiSlice } from '../../../api/metric'
import {
  IMetricFilter,
  IMetricFilterGroup,
  IMetricScope,
} from '../../../interfaces/domain/reports/metric.interface'
import { FORMAT_TYPES, DOMAIN_ENTITIES, AGG_TYPES } from '../../../constants/data.constants'
import { METRIC_TYPES } from '../../../constants/metric.constants'

type FormatType = (typeof FORMAT_TYPES)[keyof typeof FORMAT_TYPES]
type MetricLevel = (typeof DOMAIN_ENTITIES)[keyof typeof DOMAIN_ENTITIES]
type AggType = (typeof AGG_TYPES)[keyof typeof AGG_TYPES]
type MetricType = (typeof METRIC_TYPES)[keyof typeof METRIC_TYPES]

interface ICalculatedColumnMetricState {
  id?: string | null
  type: MetricType | null
  key: string | null
  label: string | null
  description: string | null
  level: MetricLevel | null
  format_type: FormatType | null
  agg_type: AggType | null
  count_distinct: boolean | null
  scope: IMetricScope | null
  filters: Array<IMetricFilter | IMetricFilterGroup> | []
  group_id: string | null
  is_system_metric: boolean | null
}

const initialState: ICalculatedColumnMetricState = {
  id: null,
  key: null,
  type: METRIC_TYPES.CALCULATED_COLUMN,
  label: null,
  description: null,
  level: null,
  format_type: null,
  agg_type: null,
  count_distinct: null,
  scope: null,
  filters: [],
  group_id: null,
  is_system_metric: false,
}

export const calculatedColumnMetricConstructorSlice = createSlice({
  name: 'calculatedColumnMetricConstructor',
  initialState,
  reducers: {
    setInitialConstructorState: () => initialState,

    setConstructorConfig: (state, action: PayloadAction<ICalculatedColumnMetricState>) => {
      const {
        id,
        type,
        key,
        label,
        description,
        level,
        format_type,
        agg_type,
        count_distinct,
        scope,
        filters,
        group_id,
        is_system_metric,
      } = action.payload
      state.id = id
      state.type = type
      state.key = key
      state.label = label
      state.description = description ?? null
      state.level = level
      state.format_type = format_type
      state.agg_type = agg_type
      state.count_distinct = typeof count_distinct !== 'undefined' ? count_distinct : null
      state.scope = scope
      state.filters = filters && filters.length > 0 ? filters : []
      state.group_id = group_id ?? null
      state.is_system_metric = is_system_metric
    },

    setMetricType: (state, action: PayloadAction<MetricType | null>) => {
      state.type = action.payload
    },

    setMetricLabel: (state, action: PayloadAction<string | null>) => {
      state.label = action.payload
    },

    setMetricDesctiption: (state, action: PayloadAction<string | null>) => {
      state.description = action.payload
    },

    setMetricLevel: (state, action: PayloadAction<MetricLevel | null>) => {
      state.level = action.payload
    },

    setMetricFormatType: (state, action: PayloadAction<FormatType | null>) => {
      state.format_type = action.payload
    },

    setMetricAggType: (state, action: PayloadAction<AggType | null>) => {
      state.agg_type = action.payload
    },

    setMetricCountDistinct: (state, action: PayloadAction<boolean | null>) => {
      state.count_distinct = action.payload
    },

    setMetricScope: (state, action: PayloadAction<IMetricScope | null>) => {
      state.scope = action.payload
    },

    setMetricScopeSelectionKey: (state, action: PayloadAction<string | undefined>) => {
      if (state.scope) {
        state.scope.selection_key = action.payload
      }
    },

    setMetricScopeSelectionVarType: (state, action: PayloadAction<string | undefined>) => {
      if (state.scope) {
        state.scope.selection_var_type = action.payload
      }
    },

    setMetricFilters: (
      state,
      action: PayloadAction<Array<IMetricFilter | IMetricFilterGroup> | []>
    ) => {
      state.filters = action.payload
    },

    setMetricGroupId: (state, action: PayloadAction<string | null>) => {
      state.group_id = action.payload
    },
  },
  extraReducers: (builder) => {
    // Set constructor state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on retrieve metric config
    builder.addMatcher(
      metricApiSlice.endpoints.getProjectMetric.matchFulfilled,
      (state, action) => {
        const { type } = action.payload
        if (type === METRIC_TYPES.CALCULATED_COLUMN) return (state = action.payload)
      }
    )
  },
})

export default calculatedColumnMetricConstructorSlice.reducer

export const {
  setInitialConstructorState,
  setConstructorConfig,
  setMetricType,
  setMetricLabel,
  setMetricDesctiption,
  setMetricLevel,
  setMetricFormatType,
  setMetricAggType,
  setMetricCountDistinct,
  setMetricScope,
  setMetricScopeSelectionKey,
  setMetricScopeSelectionVarType,
  setMetricFilters,
  setMetricGroupId,
} = calculatedColumnMetricConstructorSlice.actions
