import { DOMAIN_ENTITIES, SELECTION_METHODS } from './data.constants'

export const METRIC_TYPES = {
    CALCULATED_COLUMN: 'calculated_column',
    MEASURE: 'measure',
} as const

export const METRIC_FILTER_SCOPE_PROVIDER = {
    PARAM: 'param',
    METRIC: 'metric',
} as const

export const METRIC_SCOPES = {
    USER_DATA_COUNTED: 'user_data_counted',
    USER_DATA_AGGREGATED: 'user_data_aggregated',
    USER_PROPERTY_COUNTED: 'user_property_counted',
    USER_PROPERTY_AGGREGATED: 'user_property_aggregated',
    PROFILE_COUNTED: 'profile_counted',
    EVENT_COUNTED: 'event_counted',
    EVENT_PARAM_COUNTED: 'event_param_counted',
    EVENT_PARAM_AGGREGATED: 'event_param_aggregated',
    DEAL_COUNTED: 'deal_counted',
    DEAL_AGGREGATED: 'deal_aggregated',
    DEAL_PARAM_COUNTED: 'deal_param_counted',
    DEAL_PARAM_AGGREGATED: 'deal_param_aggregated',
    OFFER_COUNTED: 'offer_counted',
    OFFER_AGGREGATED: 'offer_aggregated',
    ORDER_COUNTED: 'order_counted',
    ORDER_AGGREGATED: 'order_aggregated',
    ORDER_PARAM_COUNTED: 'order_param_counted',
    ORDER_PARAM_AGGREGATED: 'order_param_aggregated',
    PRODUCT_COUNTED: 'product_counted',
    PRODUCT_AGGREGATED: 'product_aggregated',
    TRANSACTION_COUNTED: 'transaction_counted',
    TRANSACTION_AGGREGATED: 'transaction_aggregated',
    VISIT_COUNTED: 'visit_counted',
    VISIT_AGGREGATED: 'visit_aggregated',
    DEVICE_COUNTED: 'device_counted',
    AD_COUNTED: 'ad_counted',
    AD_AGGREGATED: 'ad_aggregated',
} as const

export const AVAILABLE_METRIC_SCOPE_TEMPLATES = {
    COUNTED: [
        {
            LEVEL: DOMAIN_ENTITIES.EVENT,
            LABEL: 'Event',
            SCOPES: [
                {
                    LABEL: 'Events',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.EVENT_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'event_id',
                        is_repeated_record: false,
                    },
                },

                {
                    LABEL: 'Event param values',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.EVENT_PARAM_COUNTED,
                        selection_method: SELECTION_METHODS.PARAM,
                        selection_column: 'event_params',
                        is_repeated_record: true,
                    },
                },
                {
                    LABEL: 'Users',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.PROFILE_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'profile_id',
                        is_repeated_record: false,
                    },
                },
                // {
                //     LABEL: 'User property values count',
                //     SCOPE_CONFIG: {
                //         scope_provider: METRIC_SCOPE_PROVIDER.PARAM,
                //         scope_key: METRIC_SCOPES.USER_PROPERTY_COUNTED,
                //         selection_method: SELECTION_METHODS.PARAM,
                //         selection_column: 'user_properties',
                //         is_repeated_record: true,
                //     },
                // },
            ],
        },
        {
            LEVEL: DOMAIN_ENTITIES.VISIT,
            LABEL: 'Visit',
            SCOPES: [
                {
                    LABEL: 'Visits',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.VISIT_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'visit_id',
                        is_repeated_record: false,
                    },
                },

                {
                    LABEL: 'Visitors',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.PROFILE_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'profile_id',
                        is_repeated_record: false,
                    },
                },
            ],
        },
        {
            LEVEL: DOMAIN_ENTITIES.DEAL,
            LABEL: 'Deal',
            SCOPES: [
                {
                    LABEL: 'Deals',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.DEAL_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'id',
                        is_repeated_record: false,
                    },
                },
                {
                    LABEL: 'Users',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.PROFILE_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'profile_id',
                        is_repeated_record: false,
                    },
                },
            ],
        },
        {
            LEVEL: DOMAIN_ENTITIES.ORDER,
            LABEL: 'Order',
            SCOPES: [
                {
                    LABEL: 'Orders',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.ORDER_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'id',
                        is_repeated_record: false,
                    },
                },
                {
                    LABEL: 'Users',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.PROFILE_COUNTED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'profile_id',
                        is_repeated_record: false,
                    },
                },
            ],
        },
    ],
    AGGREGATED: [
        {
            LEVEL: DOMAIN_ENTITIES.EVENT,
            LABEL: 'Event',
            SCOPES: [
                {
                    LABEL: 'Event param values',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.EVENT_PARAM_AGGREGATED,
                        selection_method: SELECTION_METHODS.PARAM,
                        selection_column: 'event_params',
                        is_repeated_record: true,
                    },
                },
            ],
        },
        {
            LEVEL: DOMAIN_ENTITIES.DEAL,
            LABEL: 'Deal',
            SCOPES: [
                {
                    LABEL: 'Deals amount',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.DEAL_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'value',
                        is_repeated_record: false,
                    },
                },
                {
                    LABEL: 'Deals paid amount',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.DEAL_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'paid_value',
                        is_repeated_record: false,
                    },
                },
                {
                    LABEL: 'Deals refund amount',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.DEAL_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'refund_value',
                        is_repeated_record: false,
                    },
                },
            ],
        },
        {
            LEVEL: DOMAIN_ENTITIES.ORDER,
            LABEL: 'Order',
            SCOPES: [
                {
                    LABEL: 'Orders amount',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.ORDER_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'value',
                        is_repeated_record: false,
                    },
                },
                {
                    LABEL: 'Orders paid amount',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.ORDER_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'paid_value',
                        is_repeated_record: false,
                    },
                },
                {
                    LABEL: 'Orders refund amount',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.ORDER_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'refund_value',
                        is_repeated_record: false,
                    },
                },
            ],
        },
        {
            LEVEL: DOMAIN_ENTITIES.AD,
            LABEL: 'Advertising',
            SCOPES: [
                {
                    LABEL: 'Ad budget',
                    SCOPE_CONFIG: {
                        scope_key: METRIC_SCOPES.AD_AGGREGATED,
                        selection_method: SELECTION_METHODS.COLUMN,
                        selection_column: 'cost',
                        is_repeated_record: false,
                    },
                },
            ],
        },
    ],
} as const
