// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project Imports
import { unauthorize } from '../../auth'
import { authApiSlice } from '../../../api/auth'
import { metricApiSlice } from '../../../api/metric'
import { FORMAT_TYPES } from '../../../constants/data.constants'
import { METRIC_TYPES } from '../../../constants/metric.constants'

type FormatType = (typeof FORMAT_TYPES)[keyof typeof FORMAT_TYPES]
type MetricType = (typeof METRIC_TYPES)[keyof typeof METRIC_TYPES]

interface IMetricMeasureState {
  id?: string | null
  type: MetricType | null
  key: string | null
  label: string | null
  description: string | null
  format_type: FormatType | null
  expression: string | null
  group_id: string | null
  is_system_metric: boolean | null
}

const initialState: IMetricMeasureState = {
  id: null,
  key: null,
  type: METRIC_TYPES.MEASURE,
  label: null,
  description: null,
  format_type: null,
  expression: null,
  group_id: null,
  is_system_metric: false,
}

export const measureMetricConstructorSlice = createSlice({
  name: 'measureMetricConstructor',
  initialState,
  reducers: {
    setInitialConstructorState: () => initialState,

    setMetricLabel: (state, action: PayloadAction<string | null>) => {
      state.label = action.payload
    },

    setMetricDesctiption: (state, action: PayloadAction<string | null>) => {
      state.description = action.payload
    },

    setMetricExpression: (state, action: PayloadAction<string | null>) => {
      state.expression = action.payload
    },

    setMetricFormatType: (state, action: PayloadAction<FormatType | null>) => {
      state.format_type = action.payload
    },

    setMetricGroupId: (state, action: PayloadAction<string | null>) => {
      state.group_id = action.payload
    },
  },
  extraReducers: (builder) => {
    // Set constructor state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on retrieve metric config
    builder.addMatcher(
      metricApiSlice.endpoints.getProjectMetric.matchFulfilled,
      (state, action) => {
        const { type } = action.payload
        if (type === METRIC_TYPES.MEASURE) return (state = action.payload)
      }
    )
  },
})

export default measureMetricConstructorSlice.reducer

export const {
  setInitialConstructorState,
  setMetricLabel,
  setMetricDesctiption,
  setMetricFormatType,
  setMetricExpression,
  setMetricGroupId,
} = measureMetricConstructorSlice.actions
