// ** Project imports
import { apiSlice } from '..'

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        registerUser: builder.mutation({
            query: ({ firstName, lastName, phone, email, password }) => ({
                url: 'user',
                method: 'POST',
                body: { firstName, lastName, phone, email, password },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        requestPasswordReset: builder.mutation({
            query: ({ email }) => ({
                url: 'user/requestPasswordReset',
                method: 'POST',
                body: { email },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
        }),
        resetPassword: builder.mutation({
            query: ({ email, password, passwordResetToken }) => ({
                url: `user/resetPassword/${passwordResetToken}`,
                method: 'POST',
                body: { email, password },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getUserPersonalData: builder.query({
            query: () => ({
                url: 'user/personalData',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        updateUserPersonalData: builder.mutation({
            query: ({ firstName, lastName, phone }) => ({
                url: 'user/personalData',
                method: 'PATCH',
                body: { firstName, lastName, phone },
                validateStatus: (response) => response.status >= 200 && response.status <= 200,
            }),
        }),
        updateUserPassword: builder.mutation({
            query: ({ email, currentPassword, newPassword }) => ({
                url: 'user/updatePassword',
                method: 'PATCH',
                body: { email, currentPassword, newPassword },
                validateStatus: (response) => response.status >= 200 && response.status <= 200,
                responseHandler: (response) => response.text(),
            }),
        }),
        resendConfirmationEmail: builder.mutation({
            query: () => ({
                url: 'user/resendConfirmationEmail',
                method: 'GET',
                validateStatus: (response) => response.status >= 200 && response.status <= 200,
                responseHandler: (response) => response.text(),
            }),
        }),
        confirmEmail: builder.mutation({
            query: (confirmationToken) => ({
                url: `user/confirmEmail/${confirmationToken}`,
                method: 'GET',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
        }),
        getWorkspaceData: builder.query({
            query: () => ({
                url: 'user/getWorkspaceData',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            providesTags: ['Workspace'],
        }),
    }),
})

export const {
    useRegisterUserMutation,
    useRequestPasswordResetMutation,
    useResetPasswordMutation,
    useGetUserPersonalDataQuery,
    useUpdateUserPersonalDataMutation,
    useUpdateUserPasswordMutation,
    useResendConfirmationEmailMutation,
    useConfirmEmailMutation,
    useLazyGetWorkspaceDataQuery,
} = userApiSlice
