// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface IFacebookAdsCostExportConnectionDialogs {
  createConnectionDialog: {
    isActive: boolean
  }
  updateConnectionAccessTokenDialog: {
    isActive: boolean
    connectionId: string | null
    connectionName: string | null
  }
  deleteConnectionDialog: {
    isActive: boolean
    connectionId: string | null
    connectionName: string | null
  }
}

const initialState = {
  createConnectionDialog: {
    isActive: false,
  },
  updateConnectionAccessTokenDialog: {
    isActive: false,
    connectionId: null,
    connectionName: null,
  },
  deleteConnectionDialog: {
    isActive: false,
    connectionId: null,
    connectionName: null,
  },
} as IFacebookAdsCostExportConnectionDialogs

const facebookAdsCostExportConnectionDialogSlice = createSlice({
  name: 'facebookAdsCostExportConnectionDialog',
  initialState,
  reducers: {
    toggleCreateFacebookAdsCostExportConnectionDialog(state, action: PayloadAction<boolean>) {
      state.createConnectionDialog.isActive = action.payload
    },
    toggleUpdateFacebookAdsCostExportConnectionAccessTokenDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        connectionId: string | null
        connectionName: string | null
      }>
    ) {
      const { isActive, connectionId, connectionName } = action.payload

      state.updateConnectionAccessTokenDialog.isActive = isActive
      state.updateConnectionAccessTokenDialog.connectionId = connectionId
      state.updateConnectionAccessTokenDialog.connectionName = connectionName
    },
    toggleDeleteFacebookAdsCostExportConnectionDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        connectionId: string | null
        connectionName: string | null
      }>
    ) {
      const { isActive, connectionId, connectionName } = action.payload

      state.deleteConnectionDialog.isActive = isActive
      state.deleteConnectionDialog.connectionId = connectionId
      state.deleteConnectionDialog.connectionName = connectionName
    },
  },
  extraReducers: (builder) => {
    // Set state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default facebookAdsCostExportConnectionDialogSlice.reducer

export const {
  toggleCreateFacebookAdsCostExportConnectionDialog,
  toggleUpdateFacebookAdsCostExportConnectionAccessTokenDialog,
  toggleDeleteFacebookAdsCostExportConnectionDialog,
} = facebookAdsCostExportConnectionDialogSlice.actions
