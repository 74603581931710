// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface ITikTokAdsCostExportConnectionDialogs {
  deleteConnectionDialog: {
    isActive: boolean
    connectionId: string | null
    connectionName: string | null
  }
}

const initialState = {
  deleteConnectionDialog: {
    isActive: false,
    connectionId: null,
    connectionName: null,
  },
} as ITikTokAdsCostExportConnectionDialogs

const tiktokAdsCostExportConnectionDialogSlice = createSlice({
  name: 'tiktokAdsCostExportConnectionDialog',
  initialState,
  reducers: {
    toggleDeleteTikTokAdsCostExportConnectionDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        connectionId: string | null
        connectionName: string | null
      }>
    ) {
      const { isActive, connectionId, connectionName } = action.payload

      state.deleteConnectionDialog.isActive = isActive
      state.deleteConnectionDialog.connectionId = connectionId
      state.deleteConnectionDialog.connectionName = connectionName
    },
  },
  extraReducers: (builder) => {
    // Set state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default tiktokAdsCostExportConnectionDialogSlice.reducer

export const { toggleDeleteTikTokAdsCostExportConnectionDialog } =
  tiktokAdsCostExportConnectionDialogSlice.actions
