// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { ITableColumnBand } from '../../../../interfaces/domain/reports/table-column-band.interface'
import { ReportItemTypes } from '../../../../types/reports.types'
import { AnchourElType } from '../../../../types/general.types'
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface ITrafficAttributionTableToolbar {
  isToolbarBoxVisible: boolean
  isAddTableRowAccordionVisible: boolean
  isAddTableMetricAccordionVisible: boolean
  createColumnBandDialog: {
    isActive: boolean
  }
  editColumnBandDialog: {
    isActive: boolean
    editedColumnBand: ITableColumnBand | null
  }
  deleteColumnBandDialog: {
    isActive: boolean
    deletedColumnBand: ITableColumnBand | null
  }
  dailyStatChartDialog: {
    isActive: boolean
  }
  filterConditionMenu: {
    anchorEl: any
    entityType: any
    entityId: string | null
    entityIndex: number | null
    inputCondition: {
      operator: string | null
      value: string | number | boolean | null
    } | null
  }
  legendMetricMenu: {
    anchorEl: any
    index: number | null
  }
  isSaveButtonActive: boolean
}

const initialState = {
  isToolbarBoxVisible: true,
  isAddTableRowAccordionVisible: false,
  isAddTableMetricAccordionVisible: false,
  createColumnBandDialog: {
    isActive: false,
  },
  editColumnBandDialog: {
    isActive: false,
    editedColumnBand: null,
  },
  deleteColumnBandDialog: {
    isActive: false,
    deletedColumnBand: null,
  },
  dailyStatChartDialog: {
    isActive: false,
  },
  filterConditionMenu: {
    anchorEl: null,
    entityType: null,
    entityId: null,
  },
  legendMetricMenu: {
    anchorEl: null,
    index: null,
  },
  isSaveButtonActive: false,
} as ITrafficAttributionTableToolbar

const trafficAttributionTableToolbarSlice = createSlice({
  name: 'trafficAttributionTableToolbar',
  initialState,
  reducers: {
    toggleToolbarBoxVisibility(state, action: PayloadAction<boolean>) {
      state.isToolbarBoxVisible = action.payload
    },
    toggleAddTableRowAccordionVisibility(state, action: PayloadAction<boolean>) {
      state.isAddTableRowAccordionVisible = action.payload
    },
    toggleAddTableMetricAccordionVisibility(state, action: PayloadAction<boolean>) {
      state.isAddTableMetricAccordionVisible = action.payload
    },

    toggleCreateColumnBandDialog(state, action: PayloadAction<boolean>) {
      state.createColumnBandDialog.isActive = action.payload
    },
    toggleEditColumnBandDialog(
      state,
      action: PayloadAction<{ isActive: boolean; editedColumnBand: ITableColumnBand | null }>
    ) {
      const { isActive, editedColumnBand } = action.payload

      state.editColumnBandDialog.isActive = isActive
      state.editColumnBandDialog.editedColumnBand = editedColumnBand
    },
    toggleDeleteColumnBandDialog(
      state,
      action: PayloadAction<{ isActive: boolean; deletedColumnBand: ITableColumnBand | null }>
    ) {
      const { isActive, deletedColumnBand } = action.payload

      state.deleteColumnBandDialog.isActive = isActive
      state.deleteColumnBandDialog.deletedColumnBand = deletedColumnBand
    },
    toggleDailyStatChartDialog(state, action: PayloadAction<boolean>) {
      state.dailyStatChartDialog.isActive = action.payload
    },
    openFilterConditionMenu(
      state,
      action: PayloadAction<{
        anchorEl: AnchourElType
        entityType: ReportItemTypes
        entityId: string
        entityIndex: number | null
        inputCondition?: {
          operator: string | null
          value: string | number | boolean | null
        }
      }>
    ) {
      const { anchorEl, entityType, entityId, entityIndex, inputCondition } = action.payload

      state.filterConditionMenu.anchorEl = anchorEl
      state.filterConditionMenu.entityType = entityType
      state.filterConditionMenu.entityId = entityId
      state.filterConditionMenu.entityIndex = entityIndex

      if (inputCondition) {
        state.filterConditionMenu.inputCondition = {
          operator: inputCondition.operator,
          value: inputCondition.value,
        }
      }
    },
    closeFilterConditionMenu(state) {
      state.filterConditionMenu.anchorEl = null
      state.filterConditionMenu.entityType = null
      state.filterConditionMenu.entityId = null
      state.filterConditionMenu.inputCondition = null
    },
    openLegendMetricMenu(
      state,
      action: PayloadAction<{
        anchorEl: AnchourElType
        index: number
      }>
    ) {
      const { anchorEl, index } = action.payload

      state.legendMetricMenu.anchorEl = anchorEl
      state.legendMetricMenu.index = index
    },
    closeLegendMetricMenu(state) {
      state.legendMetricMenu.anchorEl = null
      state.legendMetricMenu.index = null
    },
    setSaveButtonStatus(state, action: PayloadAction<boolean>) {
      state.isSaveButtonActive = action.payload
    },
  },
  extraReducers: (builder) => {
    // Set constructor state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default trafficAttributionTableToolbarSlice.reducer

export const {
  toggleToolbarBoxVisibility,
  toggleAddTableRowAccordionVisibility,
  toggleAddTableMetricAccordionVisibility,
  openFilterConditionMenu,
  closeFilterConditionMenu,
  toggleCreateColumnBandDialog,
  toggleEditColumnBandDialog,
  toggleDeleteColumnBandDialog,
  setSaveButtonStatus,
  toggleDailyStatChartDialog,
  openLegendMetricMenu,
  closeLegendMetricMenu,
} = trafficAttributionTableToolbarSlice.actions
