// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Project Imports
import { unauthorize } from '../auth'
import { authApiSlice } from '../../api/auth'
import { organizationApiSlice } from '../../api/organization'
import { projectApiSlice } from '../../api/project'
import { userApiSlice } from '../../api/user'

interface IOrganizationState {
    availableOrganizations: any
    activeOrganization: {
        id: number | null
        name: string | null
        role: string | null
        projects: any
    }
}

const initialState = {
    availableOrganizations: [],
    activeOrganization: {
        id: null,
        name: null,
        role: null,
        projects: [],
    },
} as IOrganizationState

export const organizationsSlice = createSlice({
    initialState,
    name: 'organizations',
    reducers: {
        setOrganizationState: (state, action) => {
            const { id, name, role, availableOrganizations, projects } = action.payload

            state.availableOrganizations =
                availableOrganizations !== undefined ? availableOrganizations : []
            state.activeOrganization.id = id !== undefined ? id : null
            state.activeOrganization.name = name !== undefined ? name : null
            state.activeOrganization.role = role !== undefined ? role : null
            state.activeOrganization.projects = projects !== undefined ? projects : []
        },
        setOrganizationInitialState: () => initialState,
        setActiveOrganization: (state, action) => {
            const { id, name, role, projects } = action.payload

            state.activeOrganization.id = id
            state.activeOrganization.name = name
            state.activeOrganization.role = role
            state.activeOrganization.projects = projects
        },
    },
    extraReducers: (builder) => {
        // Set organization state on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            return (state = initialState)
        })

        // Set organization state on getWorkspaceData
        builder.addMatcher(
            userApiSlice.endpoints.getWorkspaceData.matchFulfilled,
            (state, action) => {
                const { organizations } = action.payload.user

                // Update available organizations state
                state.availableOrganizations =
                    organizations !== undefined ? organizations : state.availableOrganizations

                // Update active organization state
                if (state.activeOrganization.id) {
                    // eslint-disable-next-line array-callback-return
                    state.activeOrganization.projects = state.availableOrganizations.map(
                        (el: any) => {
                            if (el.id === state.activeOrganization.id) {
                                return el.projects
                            }
                        }
                    )
                } else {
                    // Set default active organization state
                    const ownedOrganization = state.availableOrganizations.filter(
                        (el: any) => el.role === 'organization.owner'
                    )

                    if (ownedOrganization.length === 1) {
                        state.activeOrganization.id = ownedOrganization[0].id
                        state.activeOrganization.name = ownedOrganization[0].name
                        state.activeOrganization.role = ownedOrganization[0].role
                        state.activeOrganization.projects = ownedOrganization[0].projects
                    } else if (organizations.length > 0) {
                        state.activeOrganization.id = organizations[0].id
                        state.activeOrganization.name = organizations[0].name
                        state.activeOrganization.role = organizations[0].role
                        state.activeOrganization.projects = organizations[0].projects
                    }
                }
            }
        )

        // Set organization state on logout
        builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
            return (state = initialState)
        })

        // Set organization state after creating new organization
        builder
            .addMatcher(
                organizationApiSlice.endpoints.createOrganization.matchFulfilled,
                (state, action) => {
                    const { id, name, role, projects } = action.payload

                    state.availableOrganizations = [
                        ...state.availableOrganizations,
                        { id, name, role, projects },
                    ]
                    state.activeOrganization.id = id
                    state.activeOrganization.name = name
                    state.activeOrganization.role = role
                    state.activeOrganization.projects = projects
                }
            )
            .addMatcher(
                organizationApiSlice.endpoints.createOrganization.matchRejected,
                (state, action) => {
                    return (state = initialState)
                }
            )

        // Set organization state after updating organization
        builder.addMatcher(
            organizationApiSlice.endpoints.updateOrganization.matchFulfilled,
            (state, action) => {
                const { id, name, role, projects } = action.payload

                // Update active organization state
                state.activeOrganization.id = id
                state.activeOrganization.name = name
                state.activeOrganization.role = role
                state.activeOrganization.projects = projects

                // Update available organization state
                state.availableOrganizations.forEach((el: any) => {
                    if (el.id === id) {
                        el.name = name
                        el.role = role
                        el.projects = projects
                    }
                })
            }
        )

        // Set active project state after creating new project
        builder.addMatcher(
            projectApiSlice.endpoints.createProject.matchFulfilled,
            (state, action) => {
                const { id, streamId, timezone, currency, name, role, organizationId } =
                    action.payload

                // Update data in available organization array
                state.availableOrganizations.forEach((el: any) => {
                    if (el.id === organizationId) {
                        el.projects = [
                            ...el.projects,
                            { id, streamId, timezone, currency, name, role },
                        ]
                    }
                })

                state.activeOrganization.projects = [
                    ...state.activeOrganization.projects,
                    { id, streamId, timezone, currency, name, role },
                ]
            }
        )
    },
})

export default organizationsSlice.reducer

export const { setOrganizationState, setOrganizationInitialState, setActiveOrganization } =
    organizationsSlice.actions
