// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'
import { metricApiSlice } from '../../../../api/metric'
import {
    IMetricFilter,
    IMetricFilterGroup,
    MetricFilterScopeProvider,
} from '../../../../interfaces/domain/reports/metric.interface'
import { LOGICAL_OPERATORS } from '../../../../constants/data.constants'
import { METRIC_TYPES } from '../../../../constants/metric.constants'

interface ThreeLevelFiltersArray<T> {
    [index: number]: Array<Array<T>>
}

export interface IFilterScopeConfig {
    scopeProvider: {
        type: MetricFilterScopeProvider
        key: string
    } | null
    scopeKey: string | null
    selectionMethod: string | null
    selectionColumn: string | null
    selectionKey?: string | null
    selectionVarType: string | null
    comparisonOperator?: string
    selectionValue?: any
    isRepeatedRecord: boolean | null
    childColumn?: string | null
}

export interface IMetricFilterGroupSection {
    filters: ThreeLevelFiltersArray<IFilterScopeConfig>
}

const initialState = {
    filters: [
        [
            [
                {
                    scopeProvider: null,
                    scopeKey: null,
                    selectionMethod: null,
                    selectionColumn: null,
                    selectionKey: null,
                    selectionVarType: null,
                    isRepeatedRecord: null,
                    childColumn: null,
                },
            ],
        ],
    ],
} as IMetricFilterGroupSection

const calculatedColumnMetricConstructorFilterWidgetSlice = createSlice({
    name: 'calculatedColumnMetricConstructorFilterWidget',
    initialState,
    reducers: {
        // ** Entire state handlers
        setInitialFilterState: () => initialState,
        setFilterState: (state, action: PayloadAction<IMetricFilterGroupSection>) => {
            state = action.payload
        },
        addFirstLevelFiltersGroup(state) {
            state.filters = [
                ...(state.filters as []),
                [
                    [
                        {
                            scopeProvider: null,
                            scopeKey: null,
                            selectionMethod: null,
                            selectionColumn: null,
                            selectionKey: null,
                            selectionVarType: null,
                            isRepeatedRecord: null,
                            childColumn: null,
                        },
                    ],
                ],
            ]
        },
        deleteFirstLevelFiltersGroup(state, action: PayloadAction<number>) {
            const index = action.payload
            state.filters = (state.filters as []).filter((el: any, i: number) => i !== index)
        },
        addSecondLevelFiltersGroup(state, action: PayloadAction<number>) {
            const firstLevelIndex = action.payload

            // Get first level item
            let firstLevelItem = state.filters[firstLevelIndex]

            // Add empty second level item into first level item
            const thirdLevelNewItem = {
                scopeProvider: null,
                scopeKey: null,
                selectionMethod: null,
                selectionColumn: null,
                selectionKey: null,
                selectionVarType: null,
                isRepeatedRecord: null,
                childColumn: null,
            }

            firstLevelItem.push([thirdLevelNewItem])

            // Update state filters
            const updatedFilters = (state.filters as []).map((el: any, i: number) => {
                if (i === firstLevelIndex) {
                    return firstLevelItem
                } else return el
            })

            state.filters = updatedFilters
        },
        deleteSecondLevelFiltersGroup(state, action: PayloadAction<[number, number]>) {
            const firstLevelIndex = action.payload[0]
            const secondLevelIndex = action.payload[1]

            // Get first level item
            let firstLevelItem = state.filters[firstLevelIndex]

            // Delete second level item
            firstLevelItem = firstLevelItem.filter((el: any, i: number) => i !== secondLevelIndex)

            // Update state filters
            const updatedFilters = (state.filters as []).map((el: any, i: number) => {
                if (i === firstLevelIndex) {
                    return firstLevelItem
                } else return el
            })

            state.filters = updatedFilters
        },
        addThirdLevelFilterItem(state, action: PayloadAction<[number, number]>) {
            const firstLevelIndex = action.payload[0]
            const secondLevelIndex = action.payload[1]

            // Get first level item
            let firstLevelItem = state.filters[firstLevelIndex]

            // Get second level item
            let secondLevelItem = firstLevelItem[secondLevelIndex]

            // Add empty third level item into second level item
            const thirdLevelNewItem = {
                scopeProvider: null,
                scopeKey: null,
                selectionMethod: null,
                selectionColumn: null,
                selectionKey: null,
                selectionVarType: null,
                isRepeatedRecord: null,
                childColumn: null,
            }

            secondLevelItem.push(thirdLevelNewItem)

            // Update first level item
            firstLevelItem = firstLevelItem.map((el: any, i: number) => {
                if (i === secondLevelIndex) {
                    return secondLevelItem
                } else return el
            })

            // Update state filters
            const updatedFilters = (state.filters as []).map((el: any, i: number) => {
                if (i === firstLevelIndex) {
                    return firstLevelItem
                } else return el
            })

            state.filters = updatedFilters
        },
        deleteThirdLevelFilterItem(state, action: PayloadAction<[number, number, number]>) {
            const firstLevelIndex = action.payload[0]
            const secondLevelIndex = action.payload[1]
            const thirdLevelIndex = action.payload[2]

            // Get first level item
            let firstLevelItem = state.filters[firstLevelIndex]

            // Get second level item
            let secondLevelItem = firstLevelItem[secondLevelIndex]

            // Delete third level item
            secondLevelItem = secondLevelItem.filter((el: any, i: number) => i !== thirdLevelIndex)

            // Update first level item
            firstLevelItem = firstLevelItem
                .map((el: any, i: number) => {
                    if (i === secondLevelIndex && secondLevelItem.length > 0) {
                        return secondLevelItem
                    } else if (i !== secondLevelIndex) {
                        return el
                    }
                })
                .filter((el) => el)

            // Update state filters
            const updatedFilters = (state.filters as []).map((el: any, i: number) => {
                if (i === firstLevelIndex) {
                    return firstLevelItem
                } else return el
            })

            state.filters = updatedFilters
        },
        setThirdLevelFilterItemScopeConfig(
            state,
            action: PayloadAction<{ index: [number, number, number]; config: IFilterScopeConfig }>
        ) {
            const { index, config } = action.payload

            const {
                scopeProvider,
                scopeKey,
                selectionMethod,
                selectionColumn,
                selectionKey,
                selectionVarType,
                isRepeatedRecord,
                childColumn,
            } = config

            const firstLevelIndex = index[0]
            const secondLevelIndex = index[1]
            const thirdLevelIndex = index[2]

            // Get first level item
            let firstLevelItem = state.filters[firstLevelIndex]

            // Get second level item
            let secondLevelItem = firstLevelItem[secondLevelIndex]

            // Update third level item
            secondLevelItem = secondLevelItem.map((el: any, i: number) => {
                if (i === thirdLevelIndex) {
                    const currentScopeProviderKey = el.scopeProvider?.key
                    const newScopeProviedKey = scopeProvider?.key
                    if (currentScopeProviderKey === newScopeProviedKey) {
                        return {
                            scopeProvider,
                            scopeKey,
                            selectionMethod,
                            selectionColumn,
                            selectionKey,
                            selectionVarType,
                            comparisonOperator: el.comparisonOperator,
                            selectionValue: el.selectionValue,
                            isRepeatedRecord,
                            childColumn,
                        }
                    } else
                        return {
                            scopeProvider,
                            scopeKey,
                            selectionMethod,
                            selectionColumn,
                            selectionKey,
                            selectionVarType,
                            comparisonOperator: null,
                            selectionValue: null,
                            isRepeatedRecord,
                            childColumn,
                        }
                } else return el
            })

            // Update first level item
            firstLevelItem = firstLevelItem.map((el: any, i: number) => {
                if (i === secondLevelIndex) {
                    return secondLevelItem
                } else return el
            })

            // Update state filters
            const updatedFilters = (state.filters as []).map((el: any, i: number) => {
                if (i === firstLevelIndex) {
                    return firstLevelItem
                } else return el
            })

            state.filters = updatedFilters
        },
        setThirdLevelFilterItemValue(
            state,
            action: PayloadAction<{
                index: [number, number, number]
                config: { comparisonOperator: string; selectionValue: any }
            }>
        ) {
            const { index, config } = action.payload

            const { comparisonOperator, selectionValue } = config

            const firstLevelIndex = index[0]
            const secondLevelIndex = index[1]
            const thirdLevelIndex = index[2]

            // Get first level item
            let firstLevelItem = state.filters[firstLevelIndex]

            // Get second level item
            let secondLevelItem = firstLevelItem[secondLevelIndex]

            // Update third level item
            secondLevelItem = secondLevelItem.map((el: any, i: number) => {
                if (i === thirdLevelIndex) {
                    el.comparisonOperator = comparisonOperator
                    el.selectionValue = selectionValue
                    return el
                } else return el
            })

            // Update first level item
            firstLevelItem = firstLevelItem.map((el: any, i: number) => {
                if (i === secondLevelIndex) {
                    return secondLevelItem
                } else return el
            })

            // Update state filters
            const updatedFilters = (state.filters as []).map((el: any, i: number) => {
                if (i === firstLevelIndex) {
                    return firstLevelItem
                } else return el
            })

            state.filters = updatedFilters
        },
    },
    extraReducers: (builder) => {
        // Set constructor state on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            return (state = initialState)
        })

        // Set constructor state on logout
        builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
            return (state = initialState)
        })

        // Set constructor state on retrieve metric config
        builder.addMatcher(
            metricApiSlice.endpoints.getProjectMetric.matchFulfilled,
            (state, action) => {
                const { type } = action.payload
                if (type === METRIC_TYPES.CALCULATED_COLUMN) {
                    const metricFilters = action.payload.filters

                    let result: any = {
                        filters: [],
                    }

                    // ** If metric filters array is empty ** //
                    if (!metricFilters || metricFilters.length === 0) {
                        result.filters = [
                            [
                                [
                                    {
                                        scopeProvider: null,
                                        scopeKey: null,
                                        selectionMethod: null,
                                        selectionColumn: null,
                                        selectionKey: null,
                                        selectionVarType: null,
                                        isRepeatedRecord: null,
                                        childColumn: null,
                                    },
                                ],
                            ],
                        ]
                        return (state = result)
                    }

                    // ** Transfrom main metric filter state third item to widget filter state ** //
                    const transformMainFilterThirdItemToWidgetState = (
                        el: IMetricFilter
                    ): IFilterScopeConfig => {
                        const {
                            scope_provider,
                            scope_key,
                            selection_method,
                            selection_column,
                            selection_key,
                            selection_var_type,
                            is_repeated_record,
                            comparison_operator,
                            selection_value,
                            child_column,
                        } = el

                        const res = {
                            scopeProvider: scope_provider,
                            scopeKey: scope_key,
                            selectionMethod: selection_method,
                            selectionColumn: selection_column,
                            selectionKey: selection_key,
                            selectionVarType: selection_var_type,
                            isRepeatedRecord: is_repeated_record,
                            comparisonOperator: comparison_operator,
                            selectionValue: selection_value,
                            childColumn: child_column,
                        }

                        //@ts-ignore
                        return res
                    }

                    // ** Transform main metric filter state second group to widget fitler state ** //
                    const transformMainFilterConditionsArrToSecondLevelWidgetState = (
                        el: IMetricFilterGroup
                    ): IFilterScopeConfig[] => {
                        let res: any = []
                        // If input array with OR condition items
                        if (el.logical_operator === LOGICAL_OPERATORS[0]) {
                            el.conditions.forEach((item: any) => {
                                res.push(transformMainFilterThirdItemToWidgetState(item))
                            })
                        } else if (el.logical_operator === LOGICAL_OPERATORS[1]) {
                            el.conditions.forEach((item: any) => {
                                if (
                                    'logical_operator' in item &&
                                    item.logical_operator === LOGICAL_OPERATORS[0]
                                ) {
                                    let secondLevelGroup: any = []
                                    item.conditions.forEach((el: any) => {
                                        secondLevelGroup.push(
                                            transformMainFilterThirdItemToWidgetState(el)
                                        )
                                    })
                                    res.push(secondLevelGroup)
                                } else {
                                    res.push([transformMainFilterThirdItemToWidgetState(item)])
                                }
                            })
                        }

                        return res
                    }

                    // If first level item has third level type
                    if ('scope_provider' in metricFilters[0]) {
                        result.filters = [
                            [[transformMainFilterThirdItemToWidgetState(metricFilters[0])]],
                        ]
                    } else if ('conditions' in metricFilters[0]) {
                        if (metricFilters[0].logical_operator === LOGICAL_OPERATORS[0]) {
                            result.filters = [
                                [
                                    transformMainFilterConditionsArrToSecondLevelWidgetState(
                                        metricFilters[0]
                                    ),
                                ],
                            ]
                        } else if (metricFilters[0].logical_operator === LOGICAL_OPERATORS[1]) {
                            // If second level contains AND group render second first AND group
                            let isSecondLevelContainsAndGroup = false
                            metricFilters[0].conditions.forEach((item: any) => {
                                if (
                                    'logical_operator' in item &&
                                    item.logical_operator === LOGICAL_OPERATORS[1]
                                ) {
                                    isSecondLevelContainsAndGroup = true
                                }
                            })

                            if (!isSecondLevelContainsAndGroup) {
                                result.filters = [
                                    transformMainFilterConditionsArrToSecondLevelWidgetState(
                                        metricFilters[0]
                                    ),
                                ]
                            } else {
                                let firstLevelGroups: any = []
                                metricFilters[0].conditions.forEach((item: any) => {
                                    // Render several second group AND items
                                    if ('conditions' in item) {
                                        firstLevelGroups.push(
                                            transformMainFilterConditionsArrToSecondLevelWidgetState(
                                                item
                                            )
                                        )
                                    } else if ('scope_provider' in item) {
                                        firstLevelGroups.push([
                                            [transformMainFilterThirdItemToWidgetState(item)],
                                        ])
                                    }
                                })
                                result.filters = firstLevelGroups
                            }
                        }
                    }

                    return (state = result)
                }
            }
        )
    },
})

export default calculatedColumnMetricConstructorFilterWidgetSlice.reducer

export const {
    setInitialFilterState,
    setFilterState,
    addFirstLevelFiltersGroup,
    deleteFirstLevelFiltersGroup,
    addSecondLevelFiltersGroup,
    deleteSecondLevelFiltersGroup,
    addThirdLevelFilterItem,
    deleteThirdLevelFilterItem,
    setThirdLevelFilterItemScopeConfig,
    setThirdLevelFilterItemValue,
} = calculatedColumnMetricConstructorFilterWidgetSlice.actions
