// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState: {
        isLoading: false,
    },
    reducers: {
        setIsLoadingWorkspace(state, action) {
            state.isLoading = action.payload
        },
    },
})

export default workspaceSlice.reducer

export const { setIsLoadingWorkspace } = workspaceSlice.actions
