// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project imports
import { unauthorize } from '../../../auth'
import { authApiSlice } from '../../../../api/auth'

interface IMetricDefinitionsDialogs {
  deleteMetricDialog: {
    isActive: boolean
    metricId: string | null
    metricName: string | null
  }
  createMetricGroupDialog: {
    isActive: boolean
  }
  updateMetricGroupDialog: {
    isActive: boolean
    groupId: string | null
    metricGroupName: string | null
  }
  deleteMetricGroupDialog: {
    isActive: boolean
    groupId: string | null
    metricGroupName: string | null
  }
}

const initialState = {
  deleteMetricDialog: {
    isActive: false,
    metricId: null,
    metricName: null,
  },
  createMetricGroupDialog: {
    isActive: false,
  },
  updateMetricGroupDialog: {
    isActive: false,
    groupId: null,
  },
  deleteMetricGroupDialog: {
    isActive: false,
    groupId: null,
    metricGroupName: null,
  },
} as IMetricDefinitionsDialogs

const metricDefinitionsWidgetsSlice = createSlice({
  name: 'metricDefinitionsWidgets',
  initialState,
  reducers: {
    toggleDeleteMetricDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        metricId: string | null
        metricName: string | null
      }>
    ) {
      const { isActive, metricId, metricName } = action.payload
      state.deleteMetricDialog.isActive = isActive
      state.deleteMetricDialog.metricId = metricId
      state.deleteMetricDialog.metricName = metricName
    },
    toggleCreateMetricGroupDialog(state, action: PayloadAction<boolean>) {
      state.createMetricGroupDialog.isActive = action.payload
    },
    toggleUpdateMetricGroupDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        groupId: string | null
        metricGroupName: string | null
      }>
    ) {
      const { isActive, groupId, metricGroupName } = action.payload
      state.updateMetricGroupDialog.isActive = isActive
      state.updateMetricGroupDialog.groupId = groupId
      state.updateMetricGroupDialog.metricGroupName = metricGroupName
    },
    toggleDeleteMetricGroupDialog(
      state,
      action: PayloadAction<{
        isActive: boolean
        groupId: string | null
        metricGroupName: string | null
      }>
    ) {
      const { isActive, groupId, metricGroupName } = action.payload
      state.deleteMetricGroupDialog.isActive = isActive
      state.deleteMetricGroupDialog.groupId = groupId
      state.deleteMetricGroupDialog.metricGroupName = metricGroupName
    },
  },
  extraReducers: (builder) => {
    // Set state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })
  },
})

export default metricDefinitionsWidgetsSlice.reducer

export const {
  toggleDeleteMetricDialog,
  toggleCreateMetricGroupDialog,
  toggleUpdateMetricGroupDialog,
  toggleDeleteMetricGroupDialog,
} = metricDefinitionsWidgetsSlice.actions
