// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const CreateProject = Loadable(lazy(() => import('../../views/projects/CreateProject')))
const ProjectInfo = Loadable(lazy(() => import('../../views/projects/ProjectInfo')))
const ProjectUsers = Loadable(lazy(() => import('../../views/projects/ProjectUsers')))

// ==============================|| PROJECT ROUTING ||============================== //

const ProjectRoutes = [
    {
        path: LINK_MAP.PROJECT.ROOT,
        element: <Navigate to={LINK_MAP.PROJECT.CHILD.PROJECT_INFO} replace={true} />,
    },
    {
        path: `${LINK_MAP.PROJECT.ROOT}/${LINK_MAP.PROJECT.CHILD.CREATE}`,
        element: <CreateProject />,
    },
    {
        path: `${LINK_MAP.PROJECT.ROOT}/${LINK_MAP.PROJECT.CHILD.PROJECT_INFO}`,
        element: <ProjectInfo />,
    },
    {
        path: `${LINK_MAP.PROJECT.ROOT}/${LINK_MAP.PROJECT.CHILD.USERS}`,
        element: <ProjectUsers />,
    },
]

export default ProjectRoutes
