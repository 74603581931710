// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Project Imports
import { invitationApiSlice } from '../../api/invitation'

const initialState = {
    organizationInvitation: {
        organizationName: '',
        email: '',
        role: '',
        roleUIAlias: '',
        isRegisteredUser: false,
    },
    projectInvitation: {
        organizationName: '',
        projectName: '',
        email: '',
        role: '',
        roleUIAlias: '',
        isRegisteredUser: false,
    },
}

export const invitationsSlice = createSlice({
    initialState,
    name: 'invitations',
    extraReducers: (builder) => {
        // Set organization invitation state on load invitation info
        builder.addMatcher(
            invitationApiSlice.endpoints.getOrganizationInvitationInfo.matchFulfilled,
            (state, action) => {
                const { organizationName, email, role, isRegisteredUser } = action.payload

                state.organizationInvitation.organizationName = organizationName
                state.organizationInvitation.email = email
                state.organizationInvitation.role = role
                state.organizationInvitation.roleUIAlias =
                    role.split('.')[1].charAt(0).toUpperCase() + role.split('.')[1].slice(1)
                state.organizationInvitation.isRegisteredUser = isRegisteredUser
            }
        )

        // Set organization invitation state on accept invitation
        builder.addMatcher(
            invitationApiSlice.endpoints.acceptOrganizationInvitation.matchFulfilled,
            (state, action) => {
                return (state = initialState)
            }
        )

        // Set project invitation state on load invitation info
        builder.addMatcher(
            invitationApiSlice.endpoints.getProjectInvitationInfo.matchFulfilled,
            (state, action) => {
                const { organizationName, projectName, email, role, isRegisteredUser } =
                    action.payload

                state.projectInvitation.organizationName = organizationName
                state.projectInvitation.projectName = projectName
                state.projectInvitation.email = email
                state.projectInvitation.role = role
                state.projectInvitation.roleUIAlias =
                    role.split('.')[1].charAt(0).toUpperCase() + role.split('.')[1].slice(1)
                state.projectInvitation.isRegisteredUser = isRegisteredUser
            }
        )

        // Set organization invitation state on accept invitation
        builder.addMatcher(
            invitationApiSlice.endpoints.acceptProjectInvitation.matchFulfilled,
            (state, action) => {
                return (state = initialState)
            }
        )
    },
})

export default invitationsSlice.reducer
