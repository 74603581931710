// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Project Imports
import { authorize, unauthorize } from '../auth'
import { authApiSlice } from '../../api/auth'
import { userApiSlice } from '../../api/user'

const initialState = {
    accessToken: null,
    authGuard: false,
    guestGuard: false,
    returnRedirectUrl: null,
}

const guardSlice = createSlice({
    name: 'guard',
    initialState,
    reducers: {
        setAuthGuard(state) {
            state.authGuard = true
            state.guestGuard = false
        },
        setGuestGuard(state) {
            state.guestGuard = true
            state.authGuard = false
        },
        disableGuard(state) {
            state.guestGuard = false
            state.authGuard = false
        },
        setReturnRedirectUrl(state, action) {
            state.returnRedirectUrl = action.payload
        },
        setAccessToken(state, action) {
            state.accessToken = action.payload
        },
    },
    extraReducers: (builder) => {
        // Set guard state on authorize
        builder.addCase(authorize, (state, action) => {
            if (typeof action.payload?.guard !== 'undefined') {
                const { accessToken } = action.payload.guard
                state.accessToken = accessToken !== undefined ? accessToken : state.accessToken
            }
        })
        // Set guard state on unauthorize
        builder.addCase(unauthorize, (state, action) => {
            state.isRedirected = false
            state.accessToken = null
        })
        // Set guard state on login
        builder.addMatcher(authApiSlice.endpoints.loginUser.matchFulfilled, (state, action) => {
            const { accessToken } = action.payload
            state.accessToken = accessToken
        })
        // Set guard state on logout
        builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
            state.isRedirected = false
            state.accessToken = null
        })
        // Set user guard on register
        builder
            .addMatcher(userApiSlice.endpoints.registerUser.matchFulfilled, (state, action) => {
                const { accessToken } = action.payload
                state.accessToken = accessToken
            })
            .addMatcher(userApiSlice.endpoints.registerUser.matchRejected, (state, action) => {
                state.isRedirected = false
                state.accessToken = null
            })
        // Set user guard on reset password
        builder
            .addMatcher(userApiSlice.endpoints.resetPassword.matchFulfilled, (state, action) => {
                const { accessToken } = action.payload
                state.accessToken = accessToken
            })
            .addMatcher(userApiSlice.endpoints.resetPassword.matchRejected, (state, action) => {
                state.isRedirected = false
                state.accessToken = null
            })
    },
})

export default guardSlice.reducer

export const { setAuthGuard, setGuestGuard, disableGuard, setReturnRedirectUrl, setAccessToken } =
    guardSlice.actions
