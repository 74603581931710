// ** Project imports
import { apiSlice } from '..'

export const organizationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createOrganization: builder.mutation({
            query: ({ organizationName }) => ({
                url: 'organization',
                method: 'POST',
                body: { organizationName },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['Workspace'],
        }),
        updateOrganization: builder.mutation({
            query: ({ organizationId, organizationName }) => ({
                url: `organization/${organizationId}`,
                method: 'PATCH',
                body: { organizationName },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getOrganizationUsers: builder.query({
            query: ({ organizationId }) => ({
                url: `organization/${organizationId}/users`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            providesTags: ['OrganizationUsers'],
        }),
        addOrganizationUser: builder.mutation({
            query: ({ organizationId, email, role }) => ({
                url: `organization/${organizationId}/users`,
                method: 'POST',
                body: { email, role },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['OrganizationUsers'],
        }),
        updateOrganizationUser: builder.mutation({
            query: ({ organizationId, email, role }) => ({
                url: `organization/${organizationId}/users`,
                method: 'PATCH',
                body: { email, role },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['OrganizationUsers'],
        }),
        deleteOrganizationUser: builder.mutation({
            query: ({ organizationId, email }) => ({
                url: `organization/${organizationId}/users`,
                method: 'DELETE',
                body: { email },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['OrganizationUsers'],
        }),
        assignProjectsToUser: builder.mutation({
            query: ({ organizationId, userId, projects }) => ({
                url: `organization/${organizationId}/users/${userId}/projects`,
                method: 'PUT',
                body: [...projects],
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['OrganizationUsers'],
        }),
        getOrganizationSubscriptionInfo: builder.query({
            query: ({ organizationId, fieldsQuery }) => ({
                url: `organization/${organizationId}/subscription${
                    fieldsQuery ? '?fields=' + fieldsQuery : ''
                }`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            providesTags: ['OrganizationSubscription'],
        }),
        getOrganizationSubscriptionAddOnsSpecs: builder.query({
            query: ({ organizationId }) => ({
                url: `organization/${organizationId}/subscription/specs/add-ons`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getSystemSubscriptionPlansSpecs: builder.query({
            query: ({ organizationId }) => ({
                url: `organization/${organizationId}/subscription/specs/plans`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        setOrganizationSubscriptionPlan: builder.mutation({
            query: ({ organizationId, plan_code, add_ons }) => ({
                url: `organization/${organizationId}/subscription/plan`,
                method: 'POST',
                body: { plan_code, add_ons },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        cancelOrganizationSubscription: builder.query({
            query: ({ organizationId }) => ({
                url: `organization/${organizationId}/subscription/cancel`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['OrganizationSubscription'],
        }),
    }),
})

export const {
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useAddOrganizationUserMutation,
    useGetOrganizationUsersQuery,
    useUpdateOrganizationUserMutation,
    useDeleteOrganizationUserMutation,
    useAssignProjectsToUserMutation,
    useLazyGetOrganizationSubscriptionInfoQuery,
    useLazyGetOrganizationSubscriptionAddOnsSpecsQuery,
    useLazyGetSystemSubscriptionPlansSpecsQuery,
    useSetOrganizationSubscriptionPlanMutation,
    useLazyCancelOrganizationSubscriptionQuery,
} = organizationApiSlice
