// ** React Imports
import { Navigate } from 'react-router-dom'
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const Home = Loadable(lazy(() => import('../../views/home/Home')))

// ==============================|| HOME ROUTING ||============================== //

const HomeRoutes = [
    {
        path: '/',
        element: <Navigate to={LINK_MAP.HOME} replace={true} />,
    },
    {
        path: LINK_MAP.HOME,
        element: <Home />,
    },
]

export default HomeRoutes
