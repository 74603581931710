import { createSlice } from '@reduxjs/toolkit'

const projectUsersViewSlice = createSlice({
    name: 'projectUsersView',
    initialState: {
        inviteUserDialog: {
            isActive: false,
            isSuccessAcitve: false,
            invitedUserEmail: null,
            assignedProjectRole: null,
        },
        deleteUserDialog: {
            isActive: false,
            isSuccessAcitve: false,
            deletedUserEmail: null,
        },
        changeUserProjectRoleDialog: {
            isActive: false,
            isSuccessAcitve: false,
            userEmail: null,
            newRole: null,
            newRoleUIAlias: null,
        },
    },
    reducers: {
        toggleInviteUserDialog(state, action) {
            state.inviteUserDialog.isActive = action.payload
        },
        toggleSuccessInviteUserDialog(state, action) {
            const { isSuccessAcitve, invitedUserEmail, assignedProjectRole } = action.payload

            state.inviteUserDialog.isSuccessAcitve = isSuccessAcitve
            state.inviteUserDialog.invitedUserEmail = invitedUserEmail
                ? invitedUserEmail
                : state.inviteUserDialog.invitedUserEmail
            state.inviteUserDialog.assignedProjectRole = assignedProjectRole
                ? assignedProjectRole
                : state.inviteUserDialog.assignedProjectRole
        },
        toggleDeleteUserDialog(state, action) {
            const { isActive, deletedUserEmail } = action.payload

            state.deleteUserDialog.isActive = isActive
            state.deleteUserDialog.deletedUserEmail = deletedUserEmail
                ? deletedUserEmail
                : state.deleteUserDialog.deletedUserEmail
        },
        toggleSuccessDeleteUserDialog(state, action) {
            const { isSuccessAcitve, deletedUserEmail } = action.payload

            state.deleteUserDialog.isSuccessAcitve = isSuccessAcitve
            state.deleteUserDialog.deletedUserEmail = deletedUserEmail
                ? deletedUserEmail
                : state.deleteUserDialog.deletedUserEmail
        },
        toggleChangeUserProjectRoleDialog(state, action) {
            const { isActive, userEmail, newRole, newRoleUIAlias } = action.payload

            state.changeUserProjectRoleDialog.isActive = isActive
            state.changeUserProjectRoleDialog.userEmail = userEmail
                ? userEmail
                : state.changeUserProjectRoleDialog.userEmail
            state.changeUserProjectRoleDialog.newRole = newRole
                ? newRole
                : state.changeUserProjectRoleDialog.newRole
            state.changeUserProjectRoleDialog.newRoleUIAlias = newRoleUIAlias
                ? newRoleUIAlias
                : state.changeUserProjectRoleDialog.newRoleUIAlias
        },
        toggleSuccessChangeUserProjectRoleDialog(state, action) {
            const { isSuccessAcitve, userEmail, newRoleUIAlias } = action.payload

            state.changeUserProjectRoleDialog.isSuccessAcitve = isSuccessAcitve
            state.changeUserProjectRoleDialog.userEmail = userEmail
                ? userEmail
                : state.changeUserProjectRoleDialog.userEmail
            state.changeUserProjectRoleDialog.newRole = null
            state.changeUserProjectRoleDialog.newRoleUIAlias = newRoleUIAlias
                ? newRoleUIAlias
                : state.changeUserProjectRoleDialog.newRoleUIAlias
        },
    },
})

export default projectUsersViewSlice.reducer

export const {
    toggleInviteUserDialog,
    toggleSuccessInviteUserDialog,
    toggleDeleteUserDialog,
    toggleSuccessDeleteUserDialog,
    toggleChangeUserProjectRoleDialog,
    toggleSuccessChangeUserProjectRoleDialog,
    toggleAssignProjectsToUserDialog,
} = projectUsersViewSlice.actions
