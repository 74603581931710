// // ** Project imports
import { apiSlice } from '..'

export const metricApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProjectMetrics: builder.query({
            query: ({ organizationId, projectId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            providesTags: ['ProjectMetrics'],
        }),
        getProjectMetric: builder.query({
            query: ({ organizationId, projectId, metricId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics/${metricId}`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        createProjectMetric: builder.mutation({
            query: ({ organizationId, projectId, metricConfig }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics`,
                method: 'POST',
                body: metricConfig,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['ProjectMetrics'],
        }),
        updateProjectMetric: builder.mutation({
            query: ({ organizationId, projectId, metricId, metricConfig }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics/${metricId}`,
                method: 'PUT',
                body: metricConfig,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['ProjectMetrics'],
        }),
        deleteProjectMetric: builder.mutation({
            query: ({ organizationId, projectId, metricId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics/${metricId}`,
                method: 'DELETE',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['ProjectMetrics'],
        }),
        getProjectMetricGroups: builder.query({
            query: ({ organizationId, projectId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics-groups`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            providesTags: ['ProjectMetricGroups'],
        }),
        createProjectMetricGroup: builder.mutation({
            query: ({ organizationId, projectId, metricGroupConfig }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics-groups`,
                method: 'POST',
                body: metricGroupConfig,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['ProjectMetricGroups'],
        }),
        updateProjectMetricGroup: builder.mutation({
            query: ({ organizationId, projectId, groupId, metricGroupConfig }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics-groups/${groupId}`,
                method: 'PUT',
                body: metricGroupConfig,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['ProjectMetricGroups'],
        }),
        deleteProjectMetricGroup: builder.mutation({
            query: ({ organizationId, projectId, groupId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/metrics-groups/${groupId}`,
                method: 'DELETE',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['ProjectMetricGroups'],
        }),
    }),
})

export const {
    useGetProjectMetricsQuery,
    useLazyGetProjectMetricQuery,
    useGetProjectMetricGroupsQuery,
    useCreateProjectMetricMutation,
    useUpdateProjectMetricMutation,
    useDeleteProjectMetricMutation,
    useCreateProjectMetricGroupMutation,
    useUpdateProjectMetricGroupMutation,
    useDeleteProjectMetricGroupMutation,
} = metricApiSlice
