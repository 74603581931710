// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../shared/ui/widgets/Loadable'

// ** Lazy imports
const NonFoundPage = Loadable(lazy(() => import('../views/404')))

const NonFoundPageRoute = {
    path: '*',
    element: <NonFoundPage />,
}

export default NonFoundPageRoute
