// ** React imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const Profile = Loadable(lazy(() => import('../../views/profile/Profile/')))

// ==============================|| PROFILE ROUTING ||============================== //

const ProfileRoutes = [
    {
        path: LINK_MAP.PROFILE.ROOT,
        element: <Navigate to={LINK_MAP.PROFILE.CHILD.PERSONAL_DATA} replace={true} />,
    },
    {
        path: `${LINK_MAP.PROFILE.ROOT}/${LINK_MAP.PROFILE.CHILD.PERSONAL_DATA}`,
        element: <Profile />,
    },
    {
        path: `${LINK_MAP.PROFILE.ROOT}/${LINK_MAP.PROFILE.CHILD.SEQURITY}`,
        element: <Profile />,
    },
]

export default ProfileRoutes
