// // ** Project imports
import { apiSlice } from '..'

export const reportApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createReport: builder.mutation({
            query: ({ organizationId, projectId, type, name }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports`,
                method: 'POST',
                body: { type, name },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['ProjectReports'],
        }),
        createReportWithConfig: builder.mutation({
            query: ({ organizationId, projectId, config }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports`,
                method: 'POST',
                body: config,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            invalidatesTags: ['ProjectReports'],
        }),
        updateReportPrimaryInfo: builder.mutation({
            query: ({ organizationId, projectId, reportId, name }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports/${reportId}/primary-info`,
                method: 'PATCH',
                body: { name },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['ProjectReports'],
        }),
        updateReportConfig: builder.mutation({
            query: ({ organizationId, projectId, reportId, type, config }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports/${reportId}/config`,
                method: 'PUT',
                body: { type, config },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
        }),
        deleteReport: builder.mutation({
            query: ({ organizationId, projectId, reportId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports/${reportId}`,
                method: 'DELETE',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            invalidatesTags: ['ProjectReports'],
        }),
        getReportConfig: builder.query({
            query: ({ organizationId, projectId, reportId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports/${reportId}/config`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getProjectReports: builder.query({
            query: ({ organizationId, projectId }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
            providesTags: ['ProjectReports'],
        }),
        getReportData: builder.mutation({
            query: ({ organizationId, projectId, reportId, type, config }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports/${reportId}/data`,
                method: 'POST',
                body: { type, config },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getDailyChartData: builder.mutation({
            query: ({ organizationId, projectId, reportId, type, config }) => ({
                url: `organization/${organizationId}/projects/${projectId}/reports/${reportId}/data`,
                method: 'POST',
                body: { type, config },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
    }),
})

export const {
    useCreateReportMutation,
    useCreateReportWithConfigMutation,
    useUpdateReportPrimaryInfoMutation,
    useUpdateReportConfigMutation,
    useGetReportConfigQuery,
    useDeleteReportMutation,
    useGetProjectReportsQuery,
    useGetReportDataMutation,
    useLazyGetReportConfigQuery,
    useGetDailyChartDataMutation,
} = reportApiSlice
