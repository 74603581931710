import { createSlice } from '@reduxjs/toolkit'

const primaryAppBarSlice = createSlice({
    name: 'primaryAppBar',
    initialState: {
        sideBar: {
            isActive: false,
            isCursorOnSidebarContent: false,
            isCursorOverSidebarOpener: false,
            isFixed: false,
        },
        projectsDialog: {
            isActive: false,
        },
        count: 0,
    },
    reducers: {
        togglePrimarySideBar(state, action) {
            state.sideBar.isActive = action.payload
        },
        togglePrimarySideBarOpener(state, action) {
            state.sideBar.isCursorOverSidebarOpener = action.payload
        },
        togglePrimarySideBarContent(state, action) {
            state.sideBar.isCursorOverSidebarContent = action.payload
        },
        toggleFixPrimarySideBar(state, action) {
            state.sideBar.isFixed = action.payload
        },
        toggleProjectsDialog(state, action) {
            state.projectsDialog.isActive = action.payload
        },
    },
})

export default primaryAppBarSlice.reducer

export const {
    togglePrimarySideBar,
    toggleProjectsDialog,
    toggleFixPrimarySideBar,
    togglePrimarySideBarOpener,
    togglePrimarySideBarContent,
} = primaryAppBarSlice.actions
