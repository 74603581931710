// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const InstalledIntegrationsContent = Loadable(
    lazy(() => import('../../views/integrations/InstalledIntegrations'))
)
const CreateIntegrationContent = Loadable(
    lazy(() => import('../../views/integrations/CreateIntegration'))
)
const CreateFacebookAdsCostExportJob = Loadable(
    lazy(() => import('../../views/integrations/CreateFacebookAdsCostExportJob'))
)
const FacebookAdsCostExportSettings = Loadable(
    lazy(() => import('../../views/integrations/FacebookAdsCostExportSettings'))
)
const CreateGoogleAdsCostExportJob = Loadable(
    lazy(() => import('../../views/integrations/CreateGoogleAdsCostExportJob'))
)
const GoogleAdsCostExportSettings = Loadable(
    lazy(() => import('../../views/integrations/GoogleAdsCostExportSettings'))
)
const GoogleAdsProcessAuthCode = Loadable(
    lazy(() => import('../../components/integrations/GoogleAdsProcessAuthCode'))
)
const CreateTikTokAdsCostExportJob = Loadable(
    lazy(() => import('../../views/integrations/CreateTikTokAdsCostExportJob'))
)
const TikTokAdsCostExportSettings = Loadable(
    lazy(() => import('../../views/integrations/TikTokAdsCostExportSettings'))
)
const TikTokAdsProcessAuthCode = Loadable(
    lazy(() => import('../../components/integrations/TikTokAdsProcessAuthCode'))
)

// ==============================|| INTEGRATIONS ROUTING ||============================== //

const IntegrationsRoutes = [
    {
        path: LINK_MAP.INTEGRATIONS.ROOT,
        element: <InstalledIntegrationsContent />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.CREATE}`,
        element: <CreateIntegrationContent />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.FACEBOOK_ADS_AD_COST_EXPORT_CREATE}`,
        element: <CreateFacebookAdsCostExportJob />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.FACEBOOK_ADS_AD_COST_EXPORT_CONNECTIONS}`,
        element: <FacebookAdsCostExportSettings />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.FACEBOOK_ADS_AD_COST_EXPORT_JOBS}`,
        element: <FacebookAdsCostExportSettings />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.GOOGLE_ADS_AD_COST_EXPORT_CREATE}`,
        element: <CreateGoogleAdsCostExportJob />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.GOOGLE_ADS_AD_COST_EXPORT_CONNECTIONS}`,
        element: <GoogleAdsCostExportSettings />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.GOOGLE_ADS_AD_COST_EXPORT_JOBS}`,
        element: <GoogleAdsCostExportSettings />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.GOOGLE_ADS_AD_COST_EXPORT_CONNECTIONS_PROCESS_AUTH_CODE}`,
        element: <GoogleAdsProcessAuthCode />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.TIKTOK_ADS_AD_COST_EXPORT_CREATE}`,
        element: <CreateTikTokAdsCostExportJob />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.TIKTOK_ADS_AD_COST_EXPORT_CONNECTIONS}`,
        element: <TikTokAdsCostExportSettings />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.TIKTOK_ADS_AD_COST_EXPORT_JOBS}`,
        element: <TikTokAdsCostExportSettings />,
    },
    {
        path: `${LINK_MAP.INTEGRATIONS.ROOT}/${LINK_MAP.INTEGRATIONS.CHILD.TIKTOK_ADS_AD_COST_EXPORT_CONNECTIONS_PROCESS_AUTH_CODE}`,
        element: <TikTokAdsProcessAuthCode />,
    },
]

export default IntegrationsRoutes
