import { createSlice } from '@reduxjs/toolkit'

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        open: false,
        type: '',
        message: '',
    },
    reducers: {
        toggleSnackbarOpen(state, action) {
            const { open, type, message } = action.payload

            state.open = open
            state.type = type
            state.message = message
        },
        toggleSnackbarClose(state, action) {
            state.open = false
        },
    },
})

export default snackbarSlice.reducer

export const { toggleSnackbarOpen, toggleSnackbarClose } = snackbarSlice.actions
