// ** React Imports
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// ** MUI Imports
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material'
// import { LicenseInfo } from '@mui/x-license-pro'

// ** Project Imports
import Router from './routes'
import Guard from './components/guard/Guard'
import theme from './themes'
import { useLazyGetWorkspaceDataQuery } from './api/user'
import { setIsLoadingWorkspace } from './store/ui/widgets/workspace'

function App() {
    // Refresh workspace data query after each page reload
    const dispatch = useDispatch()
    const isAuth = useSelector((state: any) => state.user.isAuth)

    const [getWorkspaceData, { isLoading, isSuccess }] = useLazyGetWorkspaceDataQuery()
    useEffect(() => {
        if (isAuth) {
            getWorkspaceData(undefined).unwrap()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])

    useEffect(() => {
        dispatch(setIsLoadingWorkspace(isLoading))
    }, [dispatch, isLoading])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Guard isUserDataFetched={isSuccess} />
                <Router />
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App
