// ** Project imports
import { apiSlice } from '..'

export const utilsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTZList: builder.query({
            query: () => ({
                url: 'utils/getTZList',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getRolesAndPermissions: builder.query({
            query: () => ({
                url: 'utils/getRolesAndPermissions',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getCurrencyList: builder.query({
            query: () => ({
                url: 'utils/getCurrencyList',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        getTableColumsBandsStyles: builder.query({
            query: () => ({
                url: 'utils/getTableColumsBandsStyles',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
    }),
})

export const {
    useGetTZListQuery,
    useGetRolesAndPermissionsQuery,
    useGetCurrencyListQuery,
    useGetTableColumsBandsStylesQuery,
    useLazyGetTableColumsBandsStylesQuery,
} = utilsApiSlice
