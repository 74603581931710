// ** React imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const CreateOrganization = Loadable(
    lazy(() => import('../../views/organization/CreateOrganization'))
)
const OrganizationInfo = Loadable(lazy(() => import('../../views/organization/OrganizationInfo')))
const OrganizationUsers = Loadable(lazy(() => import('../../views/organization/OrganizationUsers')))
const PlanAndUsage = Loadable(lazy(() => import('../../views/organization/PlanAndUsage')))
const PlanBuilder = Loadable(lazy(() => import('../../views/organization/PlanBuilder')))
const PaymentStatus = Loadable(lazy(() => import('../../views/others/PaymentStatus')))

// ==============================|| ORGANIZATION ROUTING ||============================== //

const OrganizationRoutes = [
    {
        path: `${LINK_MAP.ORGANIZATION.ROOT}/${LINK_MAP.ORGANIZATION.CHILD.CREATE}`,
        element: <CreateOrganization />,
    },
    {
        path: LINK_MAP.ORGANIZATION.ROOT,
        element: <Navigate to={LINK_MAP.ORGANIZATION.CHILD.ORGANIZATION_INFO} replace={true} />,
    },
    {
        path: `${LINK_MAP.ORGANIZATION.ROOT}/${LINK_MAP.ORGANIZATION.CHILD.ORGANIZATION_INFO}`,
        element: <OrganizationInfo />,
    },
    {
        path: `${LINK_MAP.ORGANIZATION.ROOT}/${LINK_MAP.ORGANIZATION.CHILD.USERS}`,
        element: <OrganizationUsers />,
    },
    {
        path: `${LINK_MAP.ORGANIZATION.ROOT}/${LINK_MAP.ORGANIZATION.CHILD.PLAN_AND_USAGE}`,
        element: <PlanAndUsage />,
    },
    {
        path: `${LINK_MAP.ORGANIZATION.ROOT}/${LINK_MAP.ORGANIZATION.CHILD.PLAN_BUILDER}`,
        element: <PlanBuilder />,
    },
    {
        path: `${LINK_MAP.ORGANIZATION.ROOT}/${LINK_MAP.ORGANIZATION.CHILD.PAYMENT_STATUS}/:paymentId`,
        element: <PaymentStatus />,
    },
]

export default OrganizationRoutes
