// ** React Imports
import { lazy } from 'react'

// ** Project imports
import Loadable from '../../shared/ui/widgets/Loadable'
import { LINK_MAP } from '../../config/link-map'

// ** Lazy imports
const ClientSideTracking = Loadable(lazy(() => import('../../views/tracking/ClientSideTracking')))
const ServerSideTracking = Loadable(lazy(() => import('../../views/tracking/ServerSideTracking')))

// ==============================|| TRACKING ROUTING ||============================== //

const TrackingRoutes = [
    {
        path: `${LINK_MAP.TRACKING.ROOT}/${LINK_MAP.TRACKING.CHILD.CLIENT_SIDE}`,
        element: <ClientSideTracking />,
    },
    {
        path: `${LINK_MAP.TRACKING.ROOT}/${LINK_MAP.TRACKING.CHILD.SERVER_SIDE}`,
        element: <ServerSideTracking />,
    },
]

export default TrackingRoutes
