// ** Project imports
import { apiSlice } from '..'

export const invitationApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizationInvitationInfo: builder.query({
            query: (invitationToken) => ({
                url: `organizationInvitations/${invitationToken}`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        acceptOrganizationInvitation: builder.mutation({
            query: ({ invitationToken, isNewUser, firstName, lastName, phone, password }) => ({
                url: `organizationInvitations/${invitationToken}`,
                method: 'PATCH',
                body: { invitationToken, isNewUser, firstName, lastName, phone, password },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
        }),
        getProjectInvitationInfo: builder.query({
            query: (invitationToken) => ({
                url: `projectInvitations/${invitationToken}`,
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        acceptProjectInvitation: builder.mutation({
            query: ({ invitationToken, isNewUser, firstName, lastName, phone, password }) => ({
                url: `projectInvitations/${invitationToken}`,
                method: 'PATCH',
                body: { invitationToken, isNewUser, firstName, lastName, phone, password },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
        }),
    }),
})

export const {
    useGetOrganizationInvitationInfoQuery,
    useAcceptOrganizationInvitationMutation,
    useGetProjectInvitationInfoQuery,
    useAcceptProjectInvitationMutation,
} = invitationApiSlice
