export const DOMAIN_ENTITIES = {
    EVENT: 'event',
    USER: 'user',
    VISIT: 'visit',
    DEAL: 'deal',
    ORDER: 'order',
    TRANSACTION: 'transaction',
    DEVICE: 'device',
    AD: 'ad',
} as const

export const VAR_TYPES = {
    STRING: 'string',
    INTEGER: 'integer',
    FLOAT: 'float',
    BOOLEAN: 'boolean',
    DATE: 'date',
} as const

export const FORMAT_TYPES = {
    TEXT: 'text',
    WHOLE_NUMBER: 'whole_number',
    DECIMAL_NUMBER: 'decimal_number',
    PERCENTAGE: 'percentage',
    CURRENCY: 'currency',
    DATE: 'date',
} as const

export const AGG_TYPES = {
    SUM: 'sum',
    AVG: 'avg',
    COUNT: 'count',
    MIN: 'min',
    MAX: 'max',
} as const

export const SQL_COMPARISON_OPERATORS = [
    '=',
    '!=',
    '>',
    '<',
    '>=',
    '<=',
    'like',
    'not like',
    'is',
    'not',
] as const

export const LOGICAL_OPERATORS = ['or', 'and'] as const

export const SELECTION_METHODS = {
    COLUMN: 'column',
    PARAM: 'param',
} as const
