import { createSlice } from '@reduxjs/toolkit'

const serverSideTrackingSlice = createSlice({
    name: 'serverSideTracking',
    initialState: {
        generateApiKeyDialog: {
            isActive: false,
        },
    },
    reducers: {
        toggleGenerateApiKeyDialog(state, action) {
            state.generateApiKeyDialog.isActive = action.payload
        },
    },
})

export default serverSideTrackingSlice.reducer

export const { toggleGenerateApiKeyDialog } = serverSideTrackingSlice.actions
