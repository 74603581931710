import { createSlice } from '@reduxjs/toolkit'

const projectInfoViewSlice = createSlice({
    name: 'projectInfoView',
    initialState: {
        deleteProjectDialog: {
            isActive: false,
            isSuccessAcitve: false,
            deletedProjectName: null,
        },
    },
    reducers: {
        toggleDeleteProjectDialog(state, action) {
            const { isActive, projectName } = action.payload

            state.deleteProjectDialog.isActive = isActive
            state.deleteProjectDialog.deletedProjectName = projectName
                ? projectName
                : state.deleteProjectDialog.deletedProjectName
        },
        toggleSuccessDeleteProjectDialog(state, action) {
            const { isSuccessAcitve, projectName } = action.payload

            state.deleteProjectDialog.isSuccessAcitve = isSuccessAcitve
            state.deleteProjectDialog.deletedProjectName = projectName
                ? projectName
                : state.deleteProjectDialog.deletedProjectName
        },
    },
})

export default projectInfoViewSlice.reducer

export const { toggleDeleteProjectDialog, toggleSuccessDeleteProjectDialog } =
    projectInfoViewSlice.actions
