// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project Imports
import { REPORT_ITEM_TYPES, REPORT_TYPES } from '../../../constants/report.constants'
import { reportApiSlice } from '../../../api/report'
import { unauthorize } from '../../auth'
import { authApiSlice } from '../../../api/auth'
import {
  ParamReportConfig,
  MetricReportConfig,
  FilterCondition,
  ColumnBand,
  TableEntity,
  ReportItemTypes,
} from '../../../types/reports.types'

interface IReportDataItem {
  date_range: string
  data_segment: string | null
  [key: string]: any
}

export interface IDealsTableState {
  isLoadedReportConfig: boolean
  isLoadingReportData: boolean
  id: string | null
  name: string | null
  type: string | null
  dateRange: {
    dateStart: string | null
    dateEnd: string | null
    comparedMode: boolean
    comparedDateStart: string | null
    comparedDateEnd: string | null
  }
  dataSegments: []
  params: {
    selectedItems: ParamReportConfig[]
  }
  metrics: {
    selectedItems: MetricReportConfig[]
  }
  filters: {
    params: FilterCondition[]
    metrics: FilterCondition[]
  }
  editableParam: string | null
  editableMetric: string | null
  editableColumn: {
    entityType: ReportItemTypes | null
    entityId: string | null
  }
  columnBandsTree: ColumnBand[]
  data: {
    rows: IReportDataItem[]
    totalRows: IReportDataItem[]
  } | null
}

const initialState: IDealsTableState = {
  isLoadedReportConfig: false,
  isLoadingReportData: false,
  id: null,
  name: null,
  type: null,
  dateRange: {
    dateStart: null,
    dateEnd: null,
    comparedMode: false,
    comparedDateStart: null,
    comparedDateEnd: null,
  },
  dataSegments: [],
  params: {
    selectedItems: [],
  },
  metrics: {
    selectedItems: [],
  },
  filters: {
    params: [],
    metrics: [],
  },
  editableParam: null,
  editableMetric: null,
  editableColumn: {
    entityType: null,
    entityId: null,
  },
  columnBandsTree: [],
  data: null,
}

export const dealsTableConfigSlice = createSlice({
  name: 'dealsTableConfig',
  initialState,
  reducers: {
    // ** Entire state handlers
    setInitialReportState: () => initialState,
    setReportState: (state, action: PayloadAction<IDealsTableState>) => {
      state = action.payload
    },

    setEditableParam: (state, action: PayloadAction<string | null>) => {
      if (!action.payload) {
        state.editableParam = null
      } else {
        const paramId = action.payload

        state.editableParam = paramId
      }
    },
    setEditableMetric: (state, action: PayloadAction<string | null>) => {
      if (!action.payload) {
        state.editableMetric = null
      } else {
        const metricId = action.payload

        state.editableMetric = metricId
      }
    },
    setEditableColumn: (state, action: PayloadAction<TableEntity | null>) => {
      if (!action.payload) {
        state.editableColumn.entityId = null
        state.editableColumn.entityType = null
      } else {
        const { entityId, entityType } = action.payload

        state.editableColumn = { entityId, entityType }
      }
    },

    // ** Date range handlers
    setDateRange: (state, action: PayloadAction<[string, string]>) => {
      const dateStart = action.payload[0]
      const dateEnd = action.payload[1]

      state.dateRange.dateStart = dateStart
      state.dateRange.dateEnd = dateEnd
    },
    setDateRangeCompareMode: (state, action: PayloadAction<boolean>) => {
      state.dateRange.comparedMode = action.payload

      if (!action.payload) {
        state.dateRange.comparedDateStart = null
        state.dateRange.comparedDateEnd = null
      }
    },
    setDateRangeCompare: (state, action: PayloadAction<[string, string]>) => {
      const dateStart = action.payload[0]
      const dateEnd = action.payload[1]

      state.dateRange.comparedDateStart = dateStart
      state.dateRange.comparedDateEnd = dateEnd
    },

    // ** Report name handler
    setReportName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },

    // ** Params handlers
    addParam: (state, action: PayloadAction<ParamReportConfig>) => {
      const { id, columnBandId = null } = action.payload

      state.params.selectedItems = [...state.params.selectedItems, { id, columnBandId }]
    },
    removeParam: (state, action: PayloadAction<string>) => {
      const id = action.payload

      state.params.selectedItems = state.params.selectedItems.filter(
        (el: ParamReportConfig) => el.id !== id
      )
    },
    setParams: (state, action: PayloadAction<ParamReportConfig[]>) => {
      state.params.selectedItems = [...action.payload]
    },

    // ** Metcis handlers
    addMetric: (state, action: PayloadAction<MetricReportConfig>) => {
      const { id, columnBandId = null } = action.payload

      state.metrics.selectedItems = [...state.metrics.selectedItems, { id, columnBandId }]
    },
    removeMetric: (state, action: PayloadAction<string>) => {
      const id = action.payload

      state.metrics.selectedItems = state.metrics.selectedItems.filter(
        (el: MetricReportConfig) => el.id !== id
      )
    },
    setMetrics: (state, action: PayloadAction<MetricReportConfig[]>) => {
      state.metrics.selectedItems = [...action.payload]
    },

    // ** Column size
    setColSize: (
      state,
      action: PayloadAction<{
        entityType: Exclude<ReportItemTypes, 'column_group'>
        entityId: string
        width: number
      }>
    ) => {
      const { entityType, entityId, width } = action.payload

      if (entityType === REPORT_ITEM_TYPES.PARAM) {
        let param = state.params.selectedItems.filter((el) => el.id === entityId)[0]
        param.colWidth = Math.trunc(width)
        state.params.selectedItems = [
          ...state.params.selectedItems.map((el) => {
            if (el.id === entityId) {
              return param
            } else return el
          }),
        ]
      } else if (entityType === REPORT_ITEM_TYPES.METRIC) {
        let metric = state.metrics.selectedItems.filter((el) => el.id === entityId)[0]
        metric.colWidth = Math.trunc(width)
        state.metrics.selectedItems = [
          ...state.metrics.selectedItems.map((el) => {
            if (el.id === entityId) {
              return metric
            } else return el
          }),
        ]
      }
    },

    // ** Filter handlers
    addReportFilter: (state, action: PayloadAction<FilterCondition>) => {
      const { entityType, entityId, operator, value } = action.payload

      if (entityType === REPORT_ITEM_TYPES.PARAM) {
        state.filters.params = [...state.filters.params, { entityType, entityId, operator, value }]
      } else if (entityType === REPORT_ITEM_TYPES.METRIC) {
        state.filters.metrics = [
          ...state.filters.metrics,
          { entityType, entityId, operator, value },
        ]
      }
    },
    updateReportFilter: (state, action: PayloadAction<FilterCondition>) => {
      //@ts-ignore
      const { entityType, entityId, entityIndex, operator, value } = action.payload

      if (entityType === REPORT_ITEM_TYPES.PARAM) {
        const result = state.filters.params.map((el, i) => {
          if (i === entityIndex) {
            return { entityType, entityId, operator, value }
          } else return el
        })

        state.filters.params = result
      } else if (entityType === REPORT_ITEM_TYPES.METRIC) {
        const result = state.filters.metrics.map((el, i) => {
          if (i === entityIndex) {
            return { entityType, entityId, operator, value }
          } else return el
        })

        state.filters.metrics = result
      }
    },
    removeReportFilter: (state, action: PayloadAction<FilterCondition>) => {
      const { entityType, entityId, operator, value } = action.payload

      let currentFiltersPack: FilterCondition[]

      if (entityType === REPORT_ITEM_TYPES.PARAM) {
        currentFiltersPack = state.filters.params.filter((el) => {
          // Remove filter
          if (el.entityId === entityId && el.operator === operator && el.value === value) {
            return false
          } else return true
        })

        state.filters.params = [...currentFiltersPack]
      } else if (entityType === REPORT_ITEM_TYPES.METRIC) {
        currentFiltersPack = state.filters.metrics.filter((el) => {
          // Remove filter
          if (el.entityId === entityId && el.operator === operator && el.value === value) {
            return false
          } else return true
        })
        state.filters.metrics = [...currentFiltersPack]
      }
    },

    // ** Column bands handlers
    attachColumnBand: (
      state,
      action: PayloadAction<{
        columnBandId: string
        entityType: ReportItemTypes
        entityId: string
      }>
    ) => {
      const { columnBandId, entityType, entityId } = action.payload

      // Attach column band id to report entity
      if (entityType === REPORT_ITEM_TYPES.PARAM) {
        state.params.selectedItems = state.params.selectedItems.map((el) => {
          if (el.id === entityId) {
            return {
              id: el.id,
              columnBandId,
            }
          } else return el
        })
      } else if (entityType === REPORT_ITEM_TYPES.METRIC) {
        state.metrics.selectedItems = state.metrics.selectedItems.map((el) => {
          if (el.id === entityId) {
            return {
              id: el.id,
              columnBandId,
            }
          } else return el
        })
      }

      // Update column bands tree
      const iterateBands = (columnBands: ColumnBand[]): any => {
        let iterateBandsResult = []
        let columnBandExists = false

        const iterate = (bandObj: (ColumnBand | TableEntity)[]) => {
          let iterateResult = []

          for (const item of bandObj) {
            if ('children' in item) {
              let columnBand!: ColumnBand
              if (item?.id === columnBandId) {
                columnBand = {
                  id: columnBandId,
                  children: [...iterate(item.children), { entityType, entityId }],
                }
                columnBandExists = true
              } else if (item?.id !== columnBandId && item?.children?.length > 0) {
                columnBand = {
                  id: item.id,
                  children: [...iterate(item.children)],
                }
              }
              if (columnBand?.children?.length > 0) {
                iterateResult.push(columnBand)
              }
            } else {
              if (item?.entityType) {
                iterateResult.push(item)
              }
            }
          }

          return iterateResult
        }

        iterateBandsResult = iterate(columnBands)

        if (!columnBandExists) {
          if (iterateBandsResult?.length > 0) {
            iterateBandsResult = [
              ...iterateBandsResult,
              { id: columnBandId, children: [{ entityType, entityId }] },
            ]
          } else {
            iterateBandsResult = [{ id: columnBandId, children: [{ entityType, entityId }] }]
          }
        }

        return iterateBandsResult
      }

      state.columnBandsTree = iterateBands(state.columnBandsTree)
    },
    unpinColumnBand: (state, action: PayloadAction<TableEntity>) => {
      const { entityType, entityId } = action.payload

      // Unpin column band id to report entity
      if (entityType === REPORT_ITEM_TYPES.PARAM) {
        state.params.selectedItems = state.params.selectedItems.map((el) => {
          if (el.id === entityId) {
            return {
              id: el.id,
              columnBandId: null,
            }
          } else return el
        })
      } else if (entityType === REPORT_ITEM_TYPES.METRIC) {
        state.metrics.selectedItems = state.metrics.selectedItems.map((el) => {
          if (el.id === entityId) {
            return {
              id: el.id,
              columnBandId: null,
            }
          } else return el
        })
      }

      // Update column bands tree
      const filterBands = (columnBands: (ColumnBand | TableEntity)[]): any => {
        let result = []

        for (const item of columnBands) {
          let columnBand!: ColumnBand

          if ('children' in item) {
            columnBand = {
              id: item.id,
              children: [...filterBands(item.children)],
            }
          } else {
            if (
              item.entityType === REPORT_ITEM_TYPES.PARAM &&
              entityType === REPORT_ITEM_TYPES.PARAM &&
              item.entityId === entityId
            ) {
              continue
            } else if (
              item.entityType === REPORT_ITEM_TYPES.METRIC &&
              entityType === REPORT_ITEM_TYPES.METRIC &&
              item.entityId === entityId
            ) {
              continue
            } else {
              result.push({ entityType: item.entityType, entityId: item.entityId })
            }
          }

          if (columnBand?.children?.length > 0) {
            result.push(columnBand)
          }
        }

        return result
      }

      state.columnBandsTree = filterBands(state.columnBandsTree)
    },
  },
  extraReducers: (builder) => {
    // Set constructor state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on get report config
    builder.addMatcher(reportApiSlice.endpoints.getReportConfig.matchFulfilled, (state, action) => {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { id, type, name, config } = action.payload

      if (type === REPORT_TYPES.DEALS_TABLE) {
        state.id = id
        state.name = name
        state.type = type

        const date_range = config?.date_range ?? []
        const date_range_to_compare = config?.date_range_to_compare ?? []
        const data_segments = config?.data_segments ?? null
        const params = config?.params ?? []
        const metrics = config?.metrics ?? []
        const filters = config?.filters

        state.dateRange.dateStart = date_range[0] ?? null
        state.dateRange.dateEnd = date_range[1] ?? null
        state.dateRange.comparedMode = date_range_to_compare ? true : false
        state.dateRange.comparedDateStart = date_range_to_compare[0] ?? null
        state.dateRange.comparedDateEnd = date_range_to_compare[1] ?? null
        state.dataSegments = data_segments
        state.params.selectedItems = params.map((el: any) => {
          return {
            id: el.id,
            columnBandId: el?.column_band_id ?? null,
            colWidth: el?.col_width ?? null,
          }
        })
        state.metrics.selectedItems = metrics.map((el: any) => {
          return {
            id: el.id,
            columnBandId: el?.column_band_id ?? null,
            colWidth: el?.col_width ?? null,
          }
        })
        state.filters.params =
          filters?.params?.map((el: any) => ({
            entityType: REPORT_ITEM_TYPES.PARAM,
            entityId: el.id,
            operator: el.operator,
            value: el.value,
          })) ?? []
        state.filters.metrics =
          filters?.metrics?.map((el: any) => ({
            entityType: REPORT_ITEM_TYPES.METRIC,
            entityId: el.id,
            operator: el.operator,
            value: el.value,
          })) ?? []

        // Build column band tree
        let columnBandsTree: ColumnBand[] = []

        for (const param of params) {
          if (param?.column_band_id) {
            const columnBand = columnBandsTree.filter((el) => el.id === param.column_band_id)[0]

            // If column bands tree includes band id - add child
            if (columnBand) {
              columnBandsTree = columnBandsTree.map((el) => {
                if (el.id === param.column_band_id) {
                  return {
                    id: param.column_band_id,
                    children: [
                      ...el.children,
                      {
                        entityType: REPORT_ITEM_TYPES.PARAM,
                        entityId: param.id,
                      },
                    ],
                  }
                } else {
                  return el
                }
              })
            } else {
              // If column bands tree doesn't includes band id - add band id and child
              columnBandsTree.push({
                id: param.column_band_id,
                children: [
                  {
                    entityType: REPORT_ITEM_TYPES.PARAM,
                    entityId: param.id,
                  },
                ],
              })
            }
          }
        }

        for (const metric of metrics) {
          if (metric?.column_band_id) {
            const columnBand = columnBandsTree.filter((el) => el.id === metric.column_band_id)[0]

            // If column bands tree includes band id - add child
            if (columnBand) {
              columnBandsTree = columnBandsTree.map((el) => {
                if (el.id === metric.column_band_id) {
                  return {
                    id: metric.column_band_id,
                    children: [
                      ...el.children,
                      {
                        entityType: REPORT_ITEM_TYPES.METRIC,
                        entityId: metric.id,
                      },
                    ],
                  }
                } else {
                  return el
                }
              })
            } else {
              // If column bands tree doesn't includes band id - add band id and child
              columnBandsTree.push({
                id: metric.column_band_id,
                children: [
                  {
                    entityType: REPORT_ITEM_TYPES.METRIC,
                    entityId: metric.id,
                  },
                ],
              })
            }
          }
        }

        state.columnBandsTree = columnBandsTree

        state.isLoadedReportConfig = true
      }
    })

    // Set report data
    builder.addMatcher(reportApiSlice.endpoints.getReportData.matchFulfilled, (state, action) => {
      const { rows, totalRows } = action.payload

      const data = { rows, totalRows }

      state.data = data
      state.isLoadingReportData = false
    })
    builder.addMatcher(reportApiSlice.endpoints.getReportData.matchPending, (state, action) => {
      state.isLoadingReportData = true
    })
    builder.addMatcher(reportApiSlice.endpoints.getReportData.matchRejected, (state, action) => {
      state.isLoadingReportData = false
    })
  },
})

export default dealsTableConfigSlice.reducer

export const {
  setInitialReportState,
  setReportState,
  setReportName,
  setEditableParam,
  setEditableMetric,
  setEditableColumn,
  setDateRange,
  setDateRangeCompareMode,
  setDateRangeCompare,
  addParam,
  removeParam,
  addMetric,
  removeMetric,
  addReportFilter,
  updateReportFilter,
  removeReportFilter,
  attachColumnBand,
  unpinColumnBand,
  setParams,
  setMetrics,
  setColSize,
} = dealsTableConfigSlice.actions

// Customization example: https://codesandbox.io/s/devextreme-react-grid-for-bootstrap-4-qkeyk
