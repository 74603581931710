// ** Project imports
import { apiSlice } from '..'

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: ({ email, password, rememberUser }) => ({
                url: 'auth/login',
                method: 'POST',
                body: { email, password, rememberUser },
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
            }),
        }),
        logoutUser: builder.query({
            query: () => ({
                url: 'auth/logout',
                method: 'GET',
                validateStatus: (response) => response.status >= 200 && response.status <= 299,
                responseHandler: (response) => response.text(),
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                queryFulfilled.finally(() => dispatch(apiSlice.util.resetApiState()))
            },
        }),
    }),
})

export const { useLoginUserMutation, useLazyLogoutUserQuery } = authApiSlice
