// ** React Imports
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    createProjectStepper: {
        activeStep: 0,
    },
}

export const createProjectViewSlice = createSlice({
    initialState,
    name: 'createProjectView',
    reducers: {
        setCreateProjectStepper: (state, action) => {
            const { activeStep } = action.payload

            state.createProjectStepper.activeStep =
                activeStep !== undefined ? activeStep : state.createProjectStepper.activeStep
        },
        resetCreateProjectStepper: (state) => {
            state.createProjectStepper.activeStep = 0
        },
    },
})

export default createProjectViewSlice.reducer

export const { setCreateProjectStepper, resetCreateProjectStepper } = createProjectViewSlice.actions
